<template>
  <div></div>
  <ModalComponent
    title="Get Rules"
    :modal-active="props.show"
    :close="props.close"
    :custom-style="{
      minWidth: '800px',
      maxHeight: '600px',
      overflow: 'scroll',
    }"
  >
    <pre
      id="json"
      class="p-2 rounded bg-slate-200 cursor-pointer"
      @click="copy"
    ></pre>
  </ModalComponent>
</template>

<script setup>
import ModalComponent from '../../components/ModalComponent.vue';
import { onUpdated } from 'vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { toast } from 'vue3-toastify';

const queryBuilder_store = useQueryBuilderStore();

onUpdated(() => {
  const json = document.getElementById('json');

  if (json) {
    json.innerHTML = JSON.stringify(queryBuilder_store.getQuery, undefined, 2);
  }
});

const copy = () => {
  toast.success('Copied!');
  navigator.clipboard.writeText(JSON.stringify(queryBuilder_store.query));
};

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  close: {
    type: Function,
    default: () => 1,
  },
});
</script>
