import { defineStore } from 'pinia';
import { groupBy } from 'lodash';
import {
  sort_options_alphabetically,
  destructure_options,
} from '../utils/helpers';
import { apiGet } from '@/utils/api';
import getPath from '@/utils/getPath';

export const useRspLookupStore = defineStore('rspLookup', {
  state: () => ({
    rspLookup: [],
    phase: null,
    statuses: [],
    reasons: [],
    isLoading: false,
  }),
  getters: {
    getRspLookupPhases: state => {
      return [...new Set(state.rspLookup.map(rsp => rsp.phase))].map(item => {
        return { key: item, value: item };
      });
    },
    getRspLookupStatuses: state => {
      return phase => {
        return [
          ...new Set(
            state.rspLookup
              .filter(item => item.phase === phase)
              .map(item => item.status)
          ),
        ]
          .filter(x => x)
          .map(item => {
            return { key: item, value: item };
          });
      };
    },
    getRspLookupReasons: state => {
      return status => {
        return [
          ...new Set(
            state.rspLookup
              .filter(item => item.status === status)
              .map(item => item.reason)
          ),
        ]
          .filter(x => x)
          .map(item => {
            return { key: item, value: item };
          });
      };
    },
    getRspOptions: state => {
      const groupedRspOptions = Object.values(
        groupBy(
          Object.values(groupBy([...state.rspLookup], 'status')).flat(),
          'phase'
        )
      ).flat();

      const options = groupedRspOptions.flatMap(el => {
        let label = '';
        let child_label = '';
        let level = 0;
        let parent_ids = [];
        let child_ids = [];

        if (el.reason) {
          label = el.reason;
          level = 2;

          parent_ids = state.rspLookup
            .filter(parent => {
              return (
                (parent.phase == el.phase &&
                  parent.status == el.status &&
                  !parent.reason) ||
                (parent.phase == el.phase && !parent.status)
              );
            })
            .map(parent => parent.rsp_id);

          child_ids = [el.rsp_id];
          child_label =
            parent_ids
              .map((parent, index) => {
                if (index == 0)
                  return state.rspLookup.find(el => el.rsp_id == parent).phase;
                else if (index == 1)
                  return state.rspLookup.find(el => el.rsp_id == parent).status;
                else
                  return state.rspLookup.find(el => el.rsp_id == parent).reason;
              })
              .join(' > ') + ` > ${label}`;
        } else if (el.status) {
          label = el.status;
          level = 1;

          parent_ids = state.rspLookup
            .filter(parent => {
              return parent.phase == el.phase && !parent.status;
            })
            .map(parent => parent.rsp_id);

          child_ids = state.rspLookup
            .filter(child => {
              return child.phase == el.phase && child.status == el.status;
            })
            .map(child => child.rsp_id);

          child_label =
            parent_ids
              .map((parent, index) => {
                if (index == 0)
                  return state.rspLookup.find(el => el.rsp_id == parent).phase;
                else if (index == 1)
                  return state.rspLookup.find(el => el.rsp_id == parent).status;
                else
                  return state.rspLookup.find(el => el.rsp_id == parent).reason;
              })
              .join(' > ') + ` > ${label}`;
        } else {
          label = el.phase;

          child_ids = state.rspLookup
            .filter(child => {
              return child.phase == el.phase;
            })
            .map(child => child.rsp_id);
        }

        const value = {
          rsp_id: el.rsp_id,
          rsp_type: el.rsp_type,
        };

        return {
          label,
          child_label,
          level,
          value,
          parent_ids,
          child_ids,
        };
      });

      const organized_options = sort_options_alphabetically(options);
      const result = destructure_options(organized_options);

      return result;
    },
  },
  actions: {
    async fetchRspLookup() {
      this.isLoading = true;
      if (this.rspLookup?.length > 0) return;
      try {
        const response = await apiGet(getPath('lookups/rsp'));
        this.rspLookup = response?.data || [];
        this.isLoading = false;
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
        this.isLoading = false;
      }
    },
  },
});
