import { defineStore } from 'pinia';
import { apiGet } from '@/utils/api';
import getPath from '@/utils/getPath';

export const useDashboardsStore = defineStore('dashboards', {
  state: () => ({
    dashboards: [],
    isLoading: true,
  }),
  getters: {},
  actions: {
    async fetchGeneratedDashboardUrls() {
      this.isLoading = true;
      try {
        const response = await apiGet(
          getPath(
            'dashboards/url/',
            process.env.VUE_APP_DASHBOARDS_API_GATEWAY_ENDPOINT
          )
        );

        this.dashboards = response.dashboards;
      } catch (error) {
        const errorBody = await error.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
