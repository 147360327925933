<template>
  <svg
    id="chart-pipe"
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    viewBox="0 0 39 39"
  >
    <path
      id="Path_1122"
      data-name="Path 1122"
      d="M36.563,0H2.438A2.436,2.436,0,0,0,0,2.438V36.563A2.436,2.436,0,0,0,2.438,39H36.563A2.436,2.436,0,0,0,39,36.563V2.438A2.436,2.436,0,0,0,36.563,0Z"
      fill="#265170"
    />
    <path
      id="Path_1194"
      data-name="Path 1194"
      d="M36.563,0H2.438A2.436,2.436,0,0,0,0,2.438V36.563A2.436,2.436,0,0,0,2.438,39H36.563A2.436,2.436,0,0,0,39,36.563V2.438A2.436,2.436,0,0,0,36.563,0Zm1.219,36.563a1.219,1.219,0,0,1-1.219,1.219H2.438a1.219,1.219,0,0,1-1.219-1.219V2.438A1.219,1.219,0,0,1,2.438,1.219H36.563a1.219,1.219,0,0,1,1.219,1.219Z"
      fill="#fff"
    />
    <path
      id="Path_1123"
      data-name="Path 1123"
      d="M32.266,8H28.609A.609.609,0,0,0,28,8.609V37.25h4.875V8.609A.609.609,0,0,0,32.266,8Zm-.609,28.031H29.219V27.5h2.438Zm0-9.75H29.219V9.219h2.438Z"
      transform="translate(-10.938 -3.125)"
      fill="#fff"
    />
    <path
      id="Path_1124"
      data-name="Path 1124"
      d="M22.266,16H18.609a.609.609,0,0,0-.609.609V40.375h4.875V16.609A.609.609,0,0,0,22.266,16Zm-.609,23.156H19.219V33.063h2.438Zm0-7.312H19.219V17.219h2.438Z"
      transform="translate(-7.031 -6.25)"
      fill="#fff"
    />
    <path
      id="Path_1125"
      data-name="Path 1125"
      d="M52.266,24H48.609a.609.609,0,0,0-.609.609V43.5h4.875V24.609A.609.609,0,0,0,52.266,24Zm-.609,18.281H49.219V36.188h2.438Zm0-7.312H49.219v-9.75h2.438Z"
      transform="translate(-18.75 -9.375)"
      fill="#fff"
    />
    <path
      id="Path_1126"
      data-name="Path 1126"
      d="M42.266,32H38.609a.609.609,0,0,0-.609.609V46.625h4.875V32.609A.609.609,0,0,0,42.266,32Zm-.609,13.406H39.219V41.75h2.438Zm0-4.875H39.219V33.219h2.438Z"
      transform="translate(-14.844 -12.5)"
      fill="#fff"
    />
    <path
      id="Path_1127"
      data-name="Path 1127"
      d="M12.266,24H8.609A.609.609,0,0,0,8,24.609V43.5h4.875V24.609A.609.609,0,0,0,12.266,24Zm-.609,18.281H9.219V38.625h2.438Zm0-4.875H9.219V25.219h2.438Z"
      transform="translate(-3.125 -9.375)"
      fill="#fff"
    />
  </svg>
</template>
