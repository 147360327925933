<template>
  <div
    v-if="modalActive"
    class="fixed inset-0 bg-primary bg-opacity-80 overflow-y-auto h-full w-full z-20 flex justify-center items-center"
    @click="props.close()"
    @keyup.esc="props.close()"
  >
    <div
      :style="customStyle"
      class="h-fit w-96 bg-white rounded p-7 flex flex-col justify-between"
      :class="customClass"
      @click.stop
    >
      <div>
        <h1>{{ props.title }}</h1>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  customStyle: {
    type: Object,
    default: () => {},
  },
  modalActive: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  close: {
    type: Function,
    default: () => 1,
  },
  customClass: {
    type: String,
    default: '',
  },
});
</script>
