<template>
  <div class="flex items-center m-0.5 p-1" :class="props.class">
    <input
      :id="`${props.label}-checkbox`"
      type="checkbox"
      class="w-4 h-4 bg-gray-100 rounded-lg border-gray-300 focus:ring-offset-1 focus:ring-2 focus:ring-inherit"
      :class="{ 'ring-2 ring-red-500 ring-offset-1': props.error }"
      :checked="props.modelValue"
      :tabindex="props.tabindex"
      :disabled="props.disabled"
      @change="$emit('update:modelValue', $event.target.checked)"
      @blur="e => (props.blur ? props.blur(e) : () => {})"
    />
    <label
      :for="`${props.label}-checkbox`"
      class="ms-2 text-sm font-medium text-primary"
      >{{ label }}</label
    >
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    default: '',
    type: String,
  },
  modelValue: {
    default: false,
    type: Boolean,
  },
  error: {
    default: false,
    type: Boolean,
  },
  class: {
    default: '',
    type: String,
  },
  tabindex: {
    default: null,
    type: Number,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  blur: {
    default: null,
    type: Function,
  },
});
defineEmits(['update:modelValue']);
</script>
