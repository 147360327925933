<template>
  <div
    class="flex p-4 mb-4 text-sm rounded-lg"
    :class="{
      'text-yellow-700 bg-yellow-50': props.type == 'alert',
      'text-red-700 bg-red-50': props.type == 'warning',
    }"
    role="alert"
  >
    <div class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]">
      <div v-show="props.type == 'warning'">
        <svg
          class="w-5 h-5 text-red-700"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div v-show="props.type == 'alert'">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="#8e4b10"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
      </div>
    </div>

    <div>
      <span class="font-medium">{{ title }}</span>
      <p>
        <slot />
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
});

const title = computed(() => {
  switch (props.type) {
    case 'alert':
      return 'Important!';
    case 'warning':
      return 'Warning!';
    default:
      return 'Info';
  }
});
</script>
