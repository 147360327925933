<template>
  <PageView>
    <div class="bg-gray-100 w-full h-full cursor-default">
      <div class="flex space-between justify-between w-full h-full">
        <div
          v-show="dashboards_store.isLoading"
          class="flex items-center justify-center w-full h-full"
        >
          <LoadingSpinner />
        </div>
        <div
          v-if="!dashboards_store.isLoading"
          class="flex items-center justify-center w-full h-full"
        >
          <tabs
            ref="dashboardTabs"
            :options="{
              disableScrollBehavior: true,
              defaultTableHash: dashboards_store?.dashboards[0].name,
            }"
            panels-wrapper-class="dashboards"
            class="w-full h-full"
          >
            <tab
              v-for="dashboard in dashboards_store.dashboards"
              :key="dashboard.name"
              :name="dashboard.name"
              class="w-full h-full"
            >
              <iframe
                :src="dashboard.embed_url"
                class="flex w-full h-full"
                allowfullscreen
              >
              </iframe>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
  </PageView>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useDashboardsStore } from '@/stores/useDashboards';
import PageView from '../../components/PageVue.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

const dashboards_store = useDashboardsStore();

onMounted(async () => {
  await dashboards_store.fetchGeneratedDashboardUrls();
});

onUnmounted(() => {
  dashboards_store.dashboards = [];
});
</script>
<style scoped>
:deep(.dashboards) {
  height: 100%;
  width: 100%;
}
</style>
