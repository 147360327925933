<template>
  <ModalComponent
    :modal-active="gameplan_store.logoutModal"
    :close="() => (gameplan_store.logoutModal = false)"
    :custom-class="'w-[25em]'"
  >
    <div>
      <h1 class="text-2xl m-0.5 mb-3">
        You have unsaved changes to the Game Plan.
      </h1>
      <p>Are you sure you want to leave this page?</p>
      <div class="mt-[2em] text-left text-base text-gray-400">
        <div class="mt-5 flex justify-between">
          <OutlinedButton
            class="w-52 mr-2"
            type="button"
            @click="() => (gameplan_store.logoutModal = false)"
          >
            Cancel
          </OutlinedButton>
          <SolidButton
            class="w-52"
            type="button"
            @click="
              () => {
                gameplan_store.deleteDraftWorkStrategy().then(res => {
                  users_store.logOut();
                });
              }
            "
          >
            Continue
          </SolidButton>
        </div>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
import ModalComponent from '@/components/ModalComponent.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import SolidButton from '@/components/buttons/SolidButton.vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useUsersStore } from '@/stores/useUsers';

const users_store = useUsersStore();
const gameplan_store = useGamePlanStore();
</script>
