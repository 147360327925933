<template>
  <table class="text-left table-fixed drop-shadow-none">
    <thead>
      <tr>
        <!-- <th class="w-7 place-content-center">
          <input class="cursor-pointer mt-1.5" type="checkbox" />
        </th> -->
        <template v-for="(header, index) in HEADERS" :key="index">
          <TableHeader
            :id="header.key"
            :title="header.value"
            :show-sort="header.sort"
            :width="header.width"
            :type="header.type"
            :source="header.source"
            :reverse-sort="header.reverseSort"
            :title-styling="'text-rev-xl text-gray-700'"
            @sort="sortWipAssignments"
          />
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-show="assignments_store.isWipLoading">
        <td :colspan="HEADERS.length">
          <div class="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </td>
      </tr>
      <tr
        v-show="
          !assignments_store.isWipLoading &&
          assignments_store.wipAssignments.length === 0
        "
      >
        <td :colspan="HEADERS.length">
          <div class="flex items-center justify-center h-40">
            <p>No Items In Progress</p>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody
      v-show="!assignments_store.isWipLoading"
      class="text-rev-sm font-normal text-gray-700"
    >
      <template
        v-for="(item, index) in assignments_store.wipAssignments"
        :key="index"
      >
        <tr
          :data-index="index"
          class="z-10 cursor-pointer text-gray-700"
          :class="{
            'bg-gray-100': index % 2 === 1,
            'bg-white': index % 2 === 0,
            'outline outline-1 outline-primary-300 bg-primary-100':
              props.selectedRow === item.work_item_id,
          }"
          @click="
            props.selectedRow === item.work_item_id
              ? emit('showDetails', item.work_item_id)
              : emit('setSelectedRow', item.work_item_id)
          "
        >
          <!-- <td class="w-7 place-content-center">
            <input class="cursor-pointer mt-1" type="checkbox" />
          </td> -->
          <td
            v-for="(header, tableDataIndex) in HEADERS"
            v-show="header.key !== 'assignees'"
            :key="tableDataIndex"
            class="ml-1 w-[200px]"
            :class="
              workItemExpired(item.last_activity) == true ? 'text-red-500' : ''
            "
          >
            <TableCellView :item="item" :header="header" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <!-- <VuePagination
    v-if="!assignments_store.isWipLoading"
    :page="assignments_store.wipPage"
    local_store="wip_pagination"
    type="Items in Progress"
    :records="assignments_store.wipRecords"
    :limit="Number(assignments_store.wipLimit)"
    @paginate="paginate"
    @updateLimit="updateLimit"
  /> -->
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
// import VuePagination from '@/views/VuePagination.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { subDays, isAfter } from 'date-fns';
import { useAssignmentsStore } from '@/stores/useAssignments';
import TableCellView from '../common/TableCellView.vue';

const assignments_store = useAssignmentsStore();

const props = defineProps({
  'selected-row': {
    type: Number,
    default: null,
  },
  auxo_user_id: {
    type: String,
    default: null,
  },
});

// async function paginate(page) {
//   await assignments_store.fetchUserWip(
//     page,
//     assignments_store.wipDirection,
//     assignments_store.wipField
//   );
// }

// async function updateLimit(limit) {
//   assignments_store.wipLimit = limit.value;
//   await assignments_store.fetchUserWip(
//     1,
//     assignments_store.wipDirection,
//     assignments_store.wipField
//   );
// }

const emit = defineEmits(['showDetails', 'setSelectedRow']);

const HEADERS = [
  { key: 'claim_number', value: 'Claim Number', sort: true },
  { key: 'patient_name', value: 'Patient Name', sort: true },
  { key: 'current_plan_name', value: 'Plan', sort: true },
  { key: 'phase', value: 'Phase', sort: true },
  { key: 'status', value: 'Status', sort: true },
  { key: 'reason', value: 'Reason', sort: true },
  { key: 'activity_subtype', value: 'WIP Reason', sort: true },
  {
    key: 'time_in_column',
    value: 'Time In WIP',
    sort: true,
    reverseSort: true,
  },
  { key: 'is_priority', value: '', sort: false, width: '7em', source: 'lead' },
];

async function sortWipAssignments(direction, field) {
  const auxo_user_id = props.auxo_user_id;
  await assignments_store.fetchUserWip(auxo_user_id, 1, direction, field);
  assignments_store.wipDirection = direction;
  assignments_store.wipField = field;
  assignments_store.wipPage = 1;
  console.log(props.auxo_user_id);
}

function workItemExpired(lastActivity) {
  const deadline = subDays(new Date(), 14);
  return isAfter(deadline, new Date(lastActivity));
}
</script>

<style></style>
