<template>
  <a
    :id="`popoverElement-${props.id}`"
    :href="props.link"
    target="_blank"
    class="text-blue-500 text-rev-sm cursor-pointer"
  >
    {{ props.hoverText }}
  </a>
  <div
    :id="`popoverContent-${props.id}`"
    data-popover
    role="tooltip"
    class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72"
  >
    <div class="p-3 space-y-2">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { Popover } from 'flowbite';

const props = defineProps({
  hoverText: {
    type: String,
    default: '',
  },
  placement: {
    type: String,
    default: 'bottom',
  },
  id: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
});

onMounted(() => {
  const $targetEl = document.getElementById(`popoverContent-${props.id}`);
  const $triggerEl = document.getElementById(`popoverElement-${props.id}`);

  if ($targetEl && $triggerEl) {
    const options = {
      placement: props.placement,
      triggerType: 'hover',
      offset: 10,
    };
    new Popover($targetEl, $triggerEl, options);
  }
});
</script>
