import { defineStore } from 'pinia';
import { getAuthenticatedHeaders } from '@/utils/auth';
import * as helpers from '@/utils/helpers';
import { apiGet, apiPost, apiPut } from '@/utils/api';
import getPath from '../utils/getPath';

export const useAdminStore = defineStore('admin', {
  state: () => ({
    users: [],
    filteredUsers: [],
    existingEmails: [],
    searchCriteria: '',
    usersLoading: false,
    selectedUser: {},
    modalAction: '',
    tableViewFilter: true,
  }),
  getters: {
    sortUsers: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.filteredUsers);
      };
    },
    getFilteredUsers: state => criteria => {
      if (criteria == '') {
        state.filteredUsers = state.users;
        return state.users;
      }

      if (state.usersLoading) return [];

      const lowerSearchCriteria = criteria.toLowerCase();
      let role = '';

      const roleMapping = {
        leader: 'MGR',
        admin: 'ADM',
        contributor: 'REP',
        supporter: 'SUP',
      };

      for (const key in roleMapping) {
        if (
          lowerSearchCriteria.includes(key) ||
          key.includes(lowerSearchCriteria)
        ) {
          role = roleMapping[key];
          break;
        }
      }
      state.filteredUsers = state.users.filter(user => {
        return (
          user.full_name.toLowerCase().includes(lowerSearchCriteria) ||
          user.email.toLowerCase().includes(lowerSearchCriteria) ||
          user.user_roles.includes(role)
        );
      });
      return state.filteredUsers;
    },
    getUserById: state => {
      return userId => {
        const user = state.users.find(
          user => user.user_id || user.auxo_user_id === userId
        );
        return {
          ...user,
          initials: helpers.getUserInitials(user),
        };
      };
    },
  },
  actions: {
    async getUsers(is_active) {
      this.usersLoading = true;

      let queryStringParams =
        is_active !== null && is_active !== undefined
          ? new URLSearchParams({ is_active: is_active })
          : null;

      try {
        const response = await apiGet(getPath(`users`), queryStringParams);

        this.users = response.data
          .map(user => {
            user.photo_url = user.photo_url || 'none';
            const full_name = [user?.first_name, user?.last_name]
              .filter(e => e)
              .join(' ');
            return {
              ...user,
              username: helpers.getUsername(user.email),
              full_name,
              informal_title: helpers.formatUserRoles(user?.user_roles),
              label: full_name,
            };
          })
          .sort((a, b) => {
            return a.first_name.localeCompare(b.first_name, 'en', {
              sensitivity: 'accent',
            });
          });
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(`get users error: ${errorBody.message}`);
      } finally {
        this.usersLoading = false;
        this.filteredUsers = this.getFilteredUsers(this.searchCriteria);
      }
    },
    async fetchExistingEmails() {
      try {
        const response = await apiGet(getPath('users'));
        this.existingEmails = response.data.map(user => user.email);
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody.message);
      }
    },
    async createUser(firstName, lastName, email, photo = null, roles) {
      const payload = {
        firstName,
        lastName,
        email,
        photo,
        roles,
      };

      try {
        const response = await apiPost(getPath('users'), payload);

        if (response.status_code === 200) {
          const { data } = response;
          return {
            ...data,
          };
        } else {
          throw Error(`Could not create new auxo user.`);
        }
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody.message);
      }
    },
    async updateUser(
      auxo_user_id,
      firstName,
      lastName,
      email,
      photo,
      is_active,
      roles
    ) {
      const payload = {
        firstName,
        lastName,
        email,
        photo,
        is_active,
        roles,
      };

      try {
        const response = await apiPut(
          getPath(`users/${auxo_user_id}`),
          payload
        );

        if (response.status_code === 200) {
          const data = response.data;
          return {
            ...data,
          };
        } else {
          throw Error(`Could not create new auxo user.`);
        }
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      }
    },
    async uploadAvatar(imageData) {
      const headers = await getAuthenticatedHeaders();
      Object.assign(headers.headers, {
        'Accept-Encoding': 'gzip, deflate, br, zstd',
        'content-type': 'multipart/form-data',
      });
      const payload = { imageData };
      try {
        const response = await apiPut(
          getPath('users/avatar'),
          payload,
          headers
        );

        if (response.status_code === 200) {
          const { data } = response;
          // Return the url from s3
          return data;
        } else {
          throw Error(`Avatar upload failed.`);
        }
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody.message);
      }
    },
  },
});
