<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40.375"
    height="46"
    viewBox="0 0 40.375 46"
  >
    <g id="book-closed" transform="translate(-3.5 0.5)">
      <path
        id="Path_1188"
        data-name="Path 1188"
        d="M43.375,36.563V2.813A2.811,2.811,0,0,0,40.563,0H6.813A2.811,2.811,0,0,0,4,2.813V40.781A4.219,4.219,0,0,0,8.219,45H42.672a.7.7,0,0,0,0-1.406h-.7V37.969A1.407,1.407,0,0,0,43.375,36.563Z"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
      />
      <path
        id="Path_1192"
        data-name="Path 1192"
        d="M43.375,36.563V2.813A2.811,2.811,0,0,0,40.563,0H6.813A2.811,2.811,0,0,0,4,2.813V40.781A4.219,4.219,0,0,0,8.219,45H42.672a.7.7,0,0,0,0-1.406h-.7V37.969A1.407,1.407,0,0,0,43.375,36.563ZM5.406,2.813A1.407,1.407,0,0,1,6.813,1.406H9.625V36.563H8.219a4.2,4.2,0,0,0-2.812,1.081ZM40.563,43.594H8.219a2.813,2.813,0,0,1,0-5.625H40.563ZM11.031,36.563V1.406H40.563a1.407,1.407,0,0,1,1.406,1.406v33.75Z"
        fill="currentColor"
      />
      <path
        id="Path_1189"
        data-name="Path 1189"
        d="M36.063,26H22.7a.7.7,0,0,0,0,1.406H36.063a.7.7,0,0,0,0-1.406Z"
        transform="translate(-5.344 -7.719)"
        fill="currentColor"
      />
      <path
        id="Path_1190"
        data-name="Path 1190"
        d="M40.984,20H22.7a.7.7,0,0,0,0,1.406H40.984a.7.7,0,0,0,0-1.406Z"
        transform="translate(-5.344 -5.938)"
        fill="currentColor"
      />
      <path
        id="Path_1191"
        data-name="Path 1191"
        d="M22.7,15.406h8.438a.7.7,0,0,0,0-1.406H22.7a.7.7,0,0,0,0,1.406Z"
        transform="translate(-5.344 -4.156)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
