<template>
  <div class="max-h-[30em] overflow-auto">
    <table class="text-sm cursor-default table-fixed">
      <thead class="sticky bg-white">
        <tr>
          <template v-for="header in HEADERS" :key="header.key">
            <TableHeader
              :id="header.key"
              :title="header.value"
              :type="header.type"
              :show-sort="header.sort"
              :width="header.width"
              :centered="header.centered"
              :edit="header.edit"
              :edit-mode="gameplan_store.editMode"
            />
          </template>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(workItem, rowIndex) in gameplan_store.preview"
          :key="workItem.work_item_id"
        >
          <tr
            :data-index="rowIndex"
            :class="{
              'bg-gray-100': rowIndex % 2 === 1,
              'hover:bg-secondary-hover cursor-pointer': true,
            }"
            @click="
              () => {
                // Simulate searching this work item to allow for completion
                const search_store = useSearchStore();
                search_store.searchResults.push({
                  revology_system_number: workItem.work_item_id,
                });

                router.push({ path: `/lead/${workItem.work_item_id}` });
              }
            "
          >
            <td v-for="header in HEADERS" :key="header.key">
              <TableCellView
                :item="workItem"
                :header="header"
                :index="rowIndex"
              />
            </td>
          </tr>
        </template>
        <tr
          v-show="
            gameplan_store.previewLoading && !gameplan_store.preview?.length
          "
        >
          <td :colspan="HEADERS.length">
            <div class="flex items-center justify-center">
              <FwbSpinner size="8" class="fill-primary" />
            </div>
          </td>
        </tr>
        <tr
          v-show="
            !gameplan_store.preview?.length && !gameplan_store.previewLoading
          "
        >
          <td :colspan="HEADERS.length">
            <div class="flex items-center justify-center h-8"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import { DEFAULT_STRATEGY_ID, useGamePlanStore } from '@/stores/useGamePlans';
import { useSearchStore } from '@/stores/useSearch';
import TableCellView from '@/views/common/TableCellView.vue';
import { FwbSpinner } from 'flowbite-vue';
import { watch } from 'vue';
import 'vue3-toastify/dist/index.css';
import router from '@/router';

const gameplan_store = useGamePlanStore();

const props = defineProps({
  workListId: {
    type: Number,
    default: null,
  },
  currentTab: {
    type: String,
    default: '',
  },
});

watch(props, async () => {
  if (props.currentTab == 'Preview')
    await gameplan_store.getWorkItemPreview(
      DEFAULT_STRATEGY_ID,
      props.workListId
    );
});
const HEADERS = [
  { key: 'claim_number', value: 'Claim Number', sort: false },
  { key: 'phase', value: 'Phase', sort: false },
  { key: 'status', value: 'Status', sort: false },
  { key: 'reason', value: 'Reason', sort: false },
  { key: 'current_plan_name', value: 'Plan', sort: false },
  {
    key: 'last_auxo_activity_dtm',
    value: 'Last Activity',
    sort: false,
    type: 'date',
    format: 'Pp',
    truncateTimezone: false,
  },
];
</script>

<style scoped></style>
