<template>
  <div
    class="relative query-builder"
    :class="{
      'z-10 bg-white drop-shadow-md p-3': !gamePlanView,
      'game-plan p-3 border-primary-600 rounded-md rounded-tl-none shadow-md':
        gamePlanView,
    }"
  >
    <div v-show="!gamePlanView" class="flex justify-between">
      <p class="text-xl text-primary font-bold">Criteria</p>
    </div>
    <div
      id="query-builder"
      :class="{
        'outline outline-primary outline-1 rounded p-[1em] m-[1em]':
          !gamePlanView,
        'outline-2 !outline-[#e02424]':
          !gamePlanView && queryBuilder.attempt && !queryBuilder.query?.valid,
      }"
    >
      <p v-if="gamePlanView">
        Last edited:
        {{
          formatDate(
            queryBuilder.worklist.mod_timest,
            "MM/dd/yy 'at' h:mma",
            false
          )
        }}
        by
        {{ users_store.getUserFullName(queryBuilder.worklist.mod_by) }}
      </p>

      <div class="flex justify-between py-2 pr-2 items-end">
        <BooleanButtons
          :is-root="true"
          :rule="queryBuilder.getQuery"
          :disabled="gamePlanView || queryBuilder.defaultWorklist"
        />
      </div>
      <div class="rules-list">
        <RuleGroup
          v-for="(item, index) in queryBuilder.getQuery?.rules"
          :key="item.id"
          :rule="item"
          :level="0"
          :index-chain="[index]"
          :key-chain="[item.id]"
          :rules="queryBuilder.getQuery?.rules"
          :parent-rule="queryBuilder.getQuery"
          :fields="fields"
          :current-index="index"
          :current-length="queryBuilder.getQuery?.rules?.length"
          :game-plan-view="gamePlanView"
        />
      </div>
      <EditButtons
        v-show="!gamePlanView && !queryBuilder.defaultWorklist"
        :is-root="true"
      />
    </div>
  </div>
  <SetRuleModal :show="showSetRules" :close="setShowSetRules" />
  <GetRuleModal :show="showGetRules" :close="setShowGetRules" />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import GetRuleModal from './GetRuleModal.vue';
import SetRuleModal from './SetRuleModal.vue';
import BooleanButtons from './BooleanButtons.vue';
import EditButtons from './EditButtons.vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import RuleGroup from './RuleGroup.vue';
import { formatDate } from '@/utils/helpers';
import { useUsersStore } from '@/stores/useUsers';
import debounce from 'lodash/debounce';

const queryBuilder = useQueryBuilderStore();
const users_store = useUsersStore();

const fields = ref([]);

onMounted(() => {
  fields.value = queryBuilder.getFields;
});

// Validate rules on any changes to the query builder
const debouncedWatch = debounce(() => {
  if (queryBuilder.attempt) {
    queryBuilder.validateRules();
  }
}, 100);

watch(queryBuilder.query, debouncedWatch);

defineProps({
  save: {
    type: Function,
    default: () => 1,
  },
  cancel: {
    type: Function,
    default: () => 1,
  },
  showGetRules: {
    type: Boolean,
    default: false,
  },
  showSetRules: {
    type: Boolean,
    default: false,
  },
  setShowGetRules: {
    type: Function,
    default: () => 1,
  },
  setShowSetRules: {
    type: Function,
    default: () => 1,
  },
  reset: {
    type: Function,
    default: () => 1,
  },
  gamePlanView: {
    type: Boolean,
    default: false,
  },
  criteriaJson: {
    type: Object,
    default: () => {},
  },
});
</script>

<style scoped>
.query-builder .rules-list > .rule-group::before {
  left: 4.25em;
}

.query-builder.game-plan .rules-list > .rule-group::before {
  left: 34px;
}

.query-builder .rules-list > .rule-group:first-child::before {
  margin-top: -8px;
}

.query-builder .rules-list > .rule-group:first-child::before {
  height: calc((52px / 2) + 8px);
}
</style>
