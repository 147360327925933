<template>
  <button
    :type="props.type"
    class="inline-flex justify-center text-gray-500 items-center focus:ring-1 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium focus:z-10"
    :class="[props.class, props.overrideSizeClass || 'w-full px-5 py-2.5']"
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>
<script setup>
const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  overrideSizeClass: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  data_modal_target: {
    type: String,
    default: '',
  },
  data_modal_toggle: {
    type: String,
    default: '',
  },
});
</script>
<style scoped></style>
