<template>
  <button type="button" class="p-0 mx-1" @click="remove()">
    <img class="h-[2em]" :src="XMarkIcon" />
  </button>
</template>

<script setup>
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import XMarkIcon from '@/assets/x_mark.svg';

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  keyChain: {
    type: Array,
    default: () => [],
  },
  indexChain: {
    type: Array,
    default: () => [],
  },
});

const queryBuilder = useQueryBuilderStore();

const remove = () => {
  queryBuilder.remove(props.level, props.indexChain, props.keyChain, false);
};
</script>

<style scoped></style>
