<template>
  <svg
    aria-hidden="true"
    class="w-6 h-6 fill-gray-400 transition duration-75 group-hover:fill-white"
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1029_18045)">
      <path
        d="M6.5 9.47368C8.98528 9.47368 11 7.35293 11 4.73684C11 2.12076 8.98528 0 6.5 0C4.01472 0 2 2.12076 2 4.73684C2 7.35293 4.01472 9.47368 6.5 9.47368Z"
      />
      <path
        d="M8 10.5263H5C3.67441 10.528 2.40356 11.083 1.46622 12.0697C0.528882 13.0564 0.00158786 14.3941 0 15.7895V18.9474C0 19.2265 0.105357 19.4943 0.292893 19.6917C0.48043 19.8891 0.734784 20 1 20H12C12.2652 20 12.5196 19.8891 12.7071 19.6917C12.8946 19.4943 13 19.2265 13 18.9474V15.7895C12.9984 14.3941 12.4711 13.0564 11.5338 12.0697C10.5964 11.083 9.32559 10.528 8 10.5263Z"
      />
      <path
        d="M10.169 7.46105C10.7084 6.66602 10.9988 5.71378 11 4.73684C10.9979 4.38998 10.9596 4.04441 10.886 3.70631C10.4787 4.21972 10.1998 4.83212 10.0746 5.48785C9.9494 6.14359 9.98184 6.82187 10.169 7.46105Z"
      />
      <path
        d="M14 2.10526C13.521 2.10912 13.0467 2.20434 12.6 2.38632C13.0113 3.5517 13.1101 4.81373 12.8856 6.03427C12.6611 7.25481 12.1219 8.3868 11.327 9.30632C11.7755 9.56805 12.1957 9.88042 12.58 10.2379C13.0325 10.425 13.5139 10.5228 14 10.5263C15.0609 10.5263 16.0783 10.0827 16.8284 9.29308C17.5786 8.50346 18 7.43249 18 6.31579C18 5.19909 17.5786 4.12812 16.8284 3.3385C16.0783 2.54887 15.0609 2.10526 14 2.10526Z"
      />
      <path
        d="M15 11.5789H13.723C14.5515 12.81 14.9976 14.2808 15 15.7895V18.9474C14.9968 19.3068 14.9345 19.6629 14.816 20H19C19.2652 20 19.5196 19.8891 19.7071 19.6917C19.8946 19.4943 20 19.2265 20 18.9474V16.8421C19.9984 15.4467 19.4711 14.109 18.5338 13.1223C17.5964 12.1357 16.3256 11.5806 15 11.5789Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1029_18045">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
