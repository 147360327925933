<template>
  <PageView :not-found="showTitleBar">
    <NotFound />
  </PageView>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import PageView from '../../components/PageVue.vue';
import NotFound from './NotFound.vue';

const route = useRoute();
const showTitleBar = computed(() => route?.redirectedFrom?.path === '/login');
</script>
<style scoped></style>
