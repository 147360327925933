<template>
  <button
    :type="props.type"
    class="py-1 px-auto text-center rounded h-fit"
    :class="props.class"
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>
<script setup>
const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  data_modal_target: {
    type: String,
    default: '',
  },
  data_modal_toggle: {
    type: String,
    default: '',
  },
});
</script>
<style scoped></style>
