<template>
  <svg
    aria-hidden="true"
    class="flex-shrink-0 w-6 h-6 fill-gray-400 transition duration-75 group-hover:fill-white"
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1029_18051)">
      <path
        d="M15.7895 10.5263H9.47369V4.21053C9.47369 3.93135 9.36278 3.66361 9.16538 3.4662C8.96797 3.2688 8.70023 3.15789 8.42105 3.15789C6.75553 3.15789 5.1274 3.65178 3.74257 4.5771C2.35773 5.50241 1.27839 6.8176 0.641018 8.35635C0.00364868 9.89509 -0.163116 11.5883 0.161812 13.2218C0.48674 14.8553 1.28877 16.3558 2.46647 17.5335C3.64418 18.7112 5.14467 19.5133 6.77819 19.8382C8.41171 20.1631 10.1049 19.9964 11.6437 19.359C13.1824 18.7216 14.4976 17.6423 15.4229 16.2574C16.3482 14.8726 16.8421 13.2445 16.8421 11.5789C16.8421 11.2998 16.7312 11.032 16.5338 10.8346C16.3364 10.6372 16.0686 10.5263 15.7895 10.5263Z"
      />
      <path
        d="M11.5789 0C11.2998 0 11.032 0.110902 10.8346 0.308309C10.6372 0.505715 10.5263 0.773456 10.5263 1.05263V8.42105C10.5263 8.70023 10.6372 8.96797 10.8346 9.16537C11.032 9.36278 11.2998 9.47368 11.5789 9.47368H18.9474C19.2265 9.47368 19.4943 9.36278 19.6917 9.16537C19.8891 8.96797 20 8.70023 20 8.42105C19.9975 6.18842 19.1095 4.04795 17.5308 2.46924C15.9521 0.890528 13.8116 0.00250748 11.5789 0Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1029_18051">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
