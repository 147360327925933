<template>
  <svg
    width="40"
    height="42"
    viewBox="0 0 40 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H32C36.4183 0 40 3.58172 40 8V34C40 38.4183 36.4183 42 32 42H0V0Z"
      fill="#265170"
    />
    <path
      d="M26 27L22 23M23.3333 19.6667C23.3333 20.2795 23.2126 20.8863 22.9781 21.4525C22.7436 22.0187 22.3998 22.5332 21.9665 22.9665C21.5332 23.3998 21.0187 23.7436 20.4525 23.9781C19.8863 24.2126 19.2795 24.3333 18.6667 24.3333C18.0538 24.3333 17.447 24.2126 16.8808 23.9781C16.3146 23.7436 15.8002 23.3998 15.3668 22.9665C14.9335 22.5332 14.5898 22.0187 14.3552 21.4525C14.1207 20.8863 14 20.2795 14 19.6667C14 18.429 14.4917 17.242 15.3668 16.3668C16.242 15.4917 17.429 15 18.6667 15C19.9043 15 21.0913 15.4917 21.9665 16.3668C22.8417 17.242 23.3333 18.429 23.3333 19.6667Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
