<template>
  <div
    :id="`feedback-div-${props.messageID}`"
    v-if="props.open"
    class="flex flex-col gap-3 w-full rounded-lg border p-4 shadow-lg"
  >
    <div class="flex items-center justify-center w-full gap-2">
      <ThumbsDown fill="F05252" v-if="props.feedbackType == 'bad'" />
      <ThumbsUp fill="29CCB6" v-else />
      <h1 class="font-semibold text-primary-600 text-xl">Tell us more</h1>
    </div>
    <DropDownFlowBite
      v-if="props.feedbackType == 'bad'"
      :unique-id-string="`negative-ai-feedback-${props.messageID}`"
      text-key="text"
      button-text="Tell us what went wrong"
      :options="feedbackDropdownOptions"
      variant="secondary"
      @update-selection="
        selection => {
          inputError = false;
          dropdownSelection = selection;
        }
      "
    />
    <BaseInput
      :placeholder="
        props.feedbackType == 'good'
          ? 'What was satisfying about this response?'
          : 'Add more details...'
      "
      class="text-primary-600"
      v-model="feedbackInput"
      max-length="1000"
      :error="inputError"
      :error-message="errorMessage"
    />
    <div class="flex w-full justify-end gap-4">
      <SolidButton
        class="rounded-lg px-2 py-2 bg-primary-grey-200 border-primary-grey-200 text-rev-sm hover:bg-primary-600 hover:border-primary-600"
        @click="handleCloseModal"
        >Cancel</SolidButton
      >
      <SolidButton
        class="rounded-lg px-2 py-2 text-rev-sm"
        @click.once="handleSubmitFeedback"
        :disabled="inputError"
        >Submit
      </SolidButton>
    </div>
  </div>
  <div
    id="feedback-toast"
    v-else-if="showToast"
    class="rounded-lg border flex justify-between items-center px-4 py-2 shadow-md mb-3 mt-6 toastShow"
  >
    <ThumbsDown v-if="props.feedbackType == 'bad'" fill="F05252" />
    <ThumbsUp v-else fill="29CCB6" />
    <h1 class="font-semibold">Thanks for your feedback!</h1>

    <BaseButton @click="handleCloseToast"><img :src="CloseIcon2" /></BaseButton>
  </div>
</template>
<script setup>
import { ref, watch, defineProps, defineEmits, nextTick } from 'vue';
import { useChatbotStore } from '@/stores/useChatbot';

import DropDownFlowBite from '../DropDownFlowBite.vue';
import SolidButton from '../buttons/SolidButton.vue';
import ThumbsUp from '@/assets/chatbot-icons/ThumbsUp.vue';
import ThumbsDown from '@/assets/chatbot-icons/ThumbsDown.vue';
import BaseInput from '@/components/forms/BaseInputFlowBite.vue';
import BaseButton from '../buttons/BaseButton.vue';
import CloseIcon2 from '@/assets/close-icon-2.svg';

const props = defineProps({
  feedbackType: {
    type: String,
    default: 'good',
  },
  open: {
    type: Boolean,
    default: false,
  },
  messageID: {
    type: String,
    default: null,
  },
});

const chatbotStore = useChatbotStore();

const feedbackInput = ref('');
const dropdownSelection = ref(null);
const showToast = ref(false);
const showBadge = ref(true);
const inputError = ref(false);
const errorMessage = ref('');
const characterLimit = 1000;

const feedbackDropdownOptions = [
  { id: 1, text: 'Inaccurate information' },
  { id: 2, text: "Refused when it shouldn't have" },
  { id: 3, text: "Didn't follow instructions" },
  { id: 4, text: 'Harmful content' },
  { id: 5, text: 'Other' },
];

const calculateAndShowError = () => {
  inputError.value =
    dropdownSelection.value?.text === 'Other' && !feedbackInput.value;
  errorMessage.value = inputError.value
    ? 'You must tell us what went wrong'
    : '';
  return inputError.value;
};

const handleSubmitFeedback = async () => {
  if (calculateAndShowError()) return;
  const response = await chatbotStore.submitFeedback(
    props.feedbackType,
    dropdownSelection.value?.text,
    feedbackInput.value,
    props.messageID
  );
  if (response) {
    handleCloseModal();
    showToast.value = true;
    showBadge.value = true;
    setTimeout(handleCloseToast, 2000);
  }
};

const handleCloseModal = () => {
  feedbackInput.value = '';
  dropdownSelection.value = null;
  emit('update', false);
};

const handleCloseToast = async () => {
  await nextTick();
  const feedbackToast = document.getElementById('feedback-toast');
  if (feedbackToast) {
    feedbackToast.classList.remove('toastShow');
    feedbackToast.classList.add('toastHide');
  }

  // Wait for the animation to complete (0.5s) before hiding the toast
  setTimeout(() => {
    showToast.value = false;
  }, 500);
};

const checkCharacterCount = () => {
  if (feedbackInput.value.length > characterLimit) {
    inputError.value = true;
    errorMessage.value = `Feedback must be less than ${characterLimit} characters`;
  } else {
    inputError.value = false;
    errorMessage.value = '';
  }
};

//To make sure the character count is not larger than 1000
watch(feedbackInput, checkCharacterCount);
watch(dropdownSelection, checkCharacterCount);

const emit = defineEmits(['update']);
</script>

<style scoped>
@keyframes fadeIn {
  from {
    bottom: 30px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: 30px;
    opacity: 0;
  }
}
.toastShow {
  animation: fadeIn 0.5s ease-in-out;
}
.toastHide {
  animation: fadeOut 0.5s ease-in-out;
}
</style>
