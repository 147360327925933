<template>
  <div
    :id="props.modal_id"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
    :class="props.customClass"
    @click="props.close"
  >
    <div
      class="relative p-4 w-full max-w-3xl max-h-full"
      :style="props.customStyle"
      @click.stop
    >
      <!-- Modal content -->
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modal_id: {
    type: String,
    default: '',
  },
  customClass: {
    type: String,
    default: '',
  },
  customStyle: {
    type: Object,
    default: () => {},
  },
  close: {
    type: Function,
    default: () => 1,
  },
});
</script>
