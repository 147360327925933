<template>
  <div
    :class="{
      'rule-group ml-[1em]': true,
      'border-2': props.level,
      'rounded-md': props.level,
      [`level-${props.level}`]: true,
      'rounded-t-none border-t-0': hasItemsAbove(),
      'rounded-b-none border-b-0': hasItemsBelow(),
    }"
  >
    <div
      :class="{
        'py-2': true,
        'mx-2': true,
        'normal-rule-group': true,
        'inner-rule-group': hasItemsBelow() && props.level === 0,
      }"
    >
      <div
        v-show="props.level !== 0 && props.indexChain[props.level] === 0"
        class="flex justify-between my-2 items-end"
      >
        <BooleanButtons
          :level="props.level"
          :index-chain="props.indexChain"
          :rule="props.parentRule"
          :disabled="props.gamePlanView"
        />
        <EditButtons
          v-show="!gamePlanView"
          :rule="props.rule"
          :level="props.level"
          :index-chain="props.indexChain"
          :key-chain="props.keyChain"
          :is-top-edits="true"
        />
      </div>
      <QueryInput
        v-show="
          props.rule && 'field' in props.rule && props.rule?.field !== undefined
        "
        :level="props.level"
        :index-chain="props.indexChain"
        :key-chain="props.keyChain"
        :fields="props.fields"
        :game-plan-view="props.gamePlanView"
        :parent-rule="props.parentRule"
      />
      <div
        v-if="props.rule?.rules?.length"
        :class="{
          'rules-list relative': true,
          [`level-${props.level}`]: true,
          'inner-rule-group': hasItemsBelow(),
        }"
      >
        <RuleGroup
          v-for="(item, subIndex) in props.rule?.rules"
          :key="item.id"
          :rule="item"
          :level="props.level + 1"
          :index-chain="props.indexChain.concat(subIndex)"
          :key-chain="props.keyChain.concat(item.id)"
          :rules="props.rule?.rules"
          :parent-rule="props.rule"
          :fields="props.fields"
          :current-index="subIndex"
          :current-length="props.rule?.rules?.length"
          :game-plan-view="props.gamePlanView"
        />
      </div>
      <div
        v-if="level !== 0 && !hasItemsBelow() && !props.gamePlanView"
        class="flex justify-between my-2 items-end"
      >
        <EditButtons
          :rule="props.rule"
          :level="props.level"
          :index-chain="props.indexChain"
          :key-chain="props.keyChain"
          :is-top-edits="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BooleanButtons from './BooleanButtons.vue';
import EditButtons from './EditButtons.vue';
import QueryInput from './QueryInput.vue';

const props = defineProps({
  rule: {
    type: Object,
    default: () => {},
  },
  indexChain: {
    type: Array,
    default: () => [],
  },
  level: {
    type: Number,
    default: 0,
  },
  keyChain: {
    type: Array,
    default: () => [],
  },
  rules: {
    type: Array,
    default: () => [],
  },
  parentRule: {
    type: Object,
    default: () => {},
  },
  fields: {
    type: Array,
    default: () => [],
  },
  currentIndex: {
    type: Number,
    default: 0,
  },
  currentLength: {
    type: Number,
    default: 0,
  },
  gamePlanView: {
    type: Boolean,
    default: false,
  },
});

const hasItemsAbove = () => {
  return props.currentIndex > 0;
};

const hasItemsBelow = () => {
  return props.currentIndex < props.currentLength - 1;
};
</script>

<style scoped>
.query-builder .rules-list > .rule-group.level-1::before,
.query-builder .rules-list > .rule-group.level-2::before {
  left: 42px;
}

.query-builder.game-plan .rules-list > .rule-group.level-1::before,
.query-builder.game-plan .rules-list > .rule-group.level-2::before {
  left: 38px;
}

.query-builder .rules-list > .rule-group:first-child::before {
  margin-top: 49px;
}

.query-builder .rules-list > .rule-group:first-child::before {
  height: calc((52px / 2) + 1px);
}

.inner-rule-group.level-0 {
  border-left: none;
}

.rules-list.level-1 {
  margin-left: 1em;
}

.rules-list.level-1.inner-rule-group {
  margin-left: 1em;
}

.rule-group.level-1 {
  background-color: #effafe;
}

.rule-group.level-2 {
  background-color: white;
}
</style>
