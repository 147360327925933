<template>
  <th
    v-show="edit == undefined || (edit === true && editMode === true)"
    :style="{
      width: props.width,
    }"
    :class="props.offset"
  >
    <div
      v-if="props.title"
      ref="dropdownRef"
      class="flex relative"
      :class="[
        props.style,
        props.showSort ? 'cursor-pointer' : '',
        numericHeaders.includes(props.type)
          ? 'float-right text-right'
          : 'text-left',
        centeredHeaders.includes(props.type) ? 'ml-[3px]' : '',
        (props.type == 'date' && props.source != 'lead') || props.centered
          ? 'justify-center w-full'
          : '',
      ]"
      @mouseenter="showEllipses = true"
      @mouseleave="showEllipses = false"
      @click="toggleDropdown"
    >
      <div class="flex" :class="props.titleStyling">
        {{ props.title }}
      </div>
      <img
        :src="VerticalEllipsisSVG"
        :class="{
          'text-rev-xl text-gray-700': props.titleStyling,
          invisible: !(props.showSort && showEllipses),
          'cursor-pointer': true,
          'w-3': true,
        }"
      />
      <div
        v-show="props.showSort && showDropdown"
        class="absolute bg-white z-20 top-8 mt-0.5 drop-shadow-md truncate text-primary"
      >
        <div
          class="hover:bg-primary-100 p-2 px-3 text-rev-base text-gray-700"
          @click="sort('asc', props.id)"
        >
          Sort Ascending
        </div>
        <div
          class="hover:bg-primary-100 p-2 px-3 text-rev-base text-gray-700"
          @click="sort('desc', props.id)"
        >
          Sort Descending
        </div>
      </div>
    </div>
  </th>
</template>

<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { numericHeaders, centeredHeaders } from '../../utils/constants';
import VerticalEllipsisSVG from '@/assets/vertical-ellipsis.svg';

const showDropdown = ref(false);
const dropdownRef = ref(null);
const showEllipses = ref(false);

defineExpose({ dropdownRef });

const emit = defineEmits(['sort']);
const props = defineProps({
  title: {
    default: '',
    type: String,
  },
  id: {
    default: '',
    type: String,
  },
  showSort: {
    default: false,
    type: Boolean,
  },
  reverseSort: {
    default: false,
    type: Boolean,
  },
  type: {
    default: '',
    type: String,
  },
  source: {
    default: '',
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  style: {
    default: '',
    type: String,
  },
  width: {
    default: '',
    type: String,
  },
  offset: {
    default: '',
    type: String,
  },
  centered: {
    default: false,
    type: Boolean,
  },
  edit: {
    default: undefined,
    type: Boolean,
  },
  editMode: {
    default: false,
    type: Boolean,
  },
  titleStyling: {
    default: '',
    type: String,
  },
});

function sort(order) {
  if (props.reverseSort) {
    order = order == 'asc' ? 'desc' : 'asc';
  }
  emit('sort', order, props.id);
  closeDropdown();
}
function toggleDropdown() {
  if (props.disabled) return;
  showDropdown.value = !showDropdown.value;
}
function closeDropdown() {
  () => {
    showDropdown.value = false;
  };
}

onClickOutside(
  dropdownRef,
  () => {
    showDropdown.value = false;
  },
  {
    ignore: [],
  }
);
</script>
