<template>
  <div class="flex flex-col">
    <div class="flex items-start gap-2.5">
      <UserPicture
        :user="props.user"
        :disable-popper="true"
        :style="'border-transparent'"
      />
      <div class="flex flex-col gap-1 w-[85%] relative">
        <div class="flex items-center space-x-2 rtl:space-x-reverse">
          <span class="text-sm font-semibold text-gray-900 dark:text-white">{{
            props.user.first_name
          }}</span>
          <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
            {{
              props.timestamp.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })
            }}
          </span>
        </div>
        <div v-if="props.text" :text="props.text">
          <div
            :class="[
              'flex flex-col leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-2xl rounded-es-2xl dark:bg-gray-700',
              userFeedback.responded ? 'mb-4' : '',
            ]"
          >
            <div class="flex flex-col">
              <div
                class="font-normal text-gray-900 overflow-auto whitespace-pre-wrap break-words"
              >
                <div v-html="formatLinks(props.text)"></div>
                <div v-if="props.linkUrl">
                  [<a
                    :href="props.linkUrl"
                    target="_blank"
                    class="underline text-blue-500"
                  >
                    Source </a
                  >]
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-else class="text-sm font-normal text-gray-900 dark:text-white">
          <ChatSkeleton />
        </p>
        <div
          v-if="userFeedback.responded"
          :class="[
            'absolute bottom-1 right-0 border-2 rounded-full z-10',
            userFeedback.feedbackType == 'bad'
              ? 'border-feedback-red'
              : 'border-feedback-green',
          ]"
        >
          <ThumbsDown
            v-if="userFeedback.feedbackType == 'bad'"
            fill="F05252"
            class="w-5 h-5"
          />
          <ThumbsUp v-else fill="29CCB6" class="w-5 h-5" />
        </div>
      </div>
    </div>
    <div
      v-if="props.text && !userFeedback.responded"
      class="flex gap-2 text-rev-sm justify-end py-4"
    >
      <button
        class="flex items-center hover:text-feedback-green"
        @click="handleOpenFeedback('good')"
      >
        <ThumbsUp
          class="w-6 h-6"
          :fill="
            detailedFeedbackOpen && detailedFeedbackType == 'good'
              ? '29CCB6'
              : 'AAAAAA'
          "
        />
        <span
          :class="
            detailedFeedbackOpen && detailedFeedbackType == 'good'
              ? 'text-feedback-green'
              : ''
          "
          >Good Response</span
        >
      </button>
      <button
        class="flex items-center hover:text-feedback-red"
        @click="handleOpenFeedback('bad')"
      >
        <ThumbsDown
          class="w-6 h-6 hover:text-feedback-red"
          :fill="
            detailedFeedbackOpen && detailedFeedbackType == 'bad'
              ? 'F05252'
              : 'AAAAAA'
          "
        />
        <span
          :class="
            detailedFeedbackOpen && detailedFeedbackType == 'bad'
              ? 'text-feedback-red'
              : ''
          "
          >Bad Response</span
        >
      </button>
    </div>
    <UserFeedback
      :open="detailedFeedbackOpen"
      :message-i-d="props.messageID"
      :feedback-type="detailedFeedbackType"
      @update="
        event => {
          detailedFeedbackOpen = event;
        }
      "
    />
  </div>
</template>
<script setup>
import { ref, watch, nextTick, onMounted } from 'vue';

import UserPicture from '../UserPicture.vue';
import ChatAvatar from '@/assets/chatAvatar.svg';
import ThumbsUp from '@/assets/chatbot-icons/ThumbsUp.vue';
import ThumbsDown from '@/assets/chatbot-icons/ThumbsDown.vue';
import UserFeedback from './UserFeedback.vue';
import ChatSkeleton from './ChatSkeleton.vue';

import { useChatbotStore } from '@/stores/useChatbot';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  messageID: {
    type: String,
    default: null,
  },
  linkUrl: {
    type: String,
    default: '',
  },
  timestamp: {
    type: Date,
    default: new Date(),
  },
  user: {
    type: {},
    default: {
      first_name: 'Auxo AI',
      last_name: '',
      initials: 'AI',
      photo_url: ChatAvatar,
    },
  },
});

const chatbotStore = useChatbotStore();

let detailedFeedbackOpen = ref(false);
let detailedFeedbackType = ref('good');
let userFeedback = ref({ responded: false, feedbackType: 'good' });

const handleOpenFeedback = feedbackType => {
  detailedFeedbackType.value = feedbackType;
  detailedFeedbackOpen.value = true;
  nextTick(() => {
    const feedbackContainer = document.getElementById(
      `feedback-div-${props.messageID}`
    );
    if (feedbackContainer) {
      feedbackContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  });
};

onMounted(() => {
  const feedbackObj = chatbotStore.fetchFeedbackByID(props.messageID);
  userFeedback.value = { ...feedbackObj[0] };
});

watch(
  () => chatbotStore.fetchFeedbackByID(props.messageID),
  newFeedback => {
    userFeedback.value = { ...newFeedback[0] };
  },
  { deep: true } // Use deep watching because userFeedback is an array
);

const formatLinks = text => {
  // To avoid fake links, the prompt tells the bot to reference the link's number in the response. This removes it
  text = text.replace(/\[\d+\]/g, '');
  // urls are formatted in <url> tags in the response, so this makes them clickable
  return text.replace(
    /<url>([^<]+)<\/url>/g,
    '<a href="$1" target="_blank" class="text-blue-500 underline">$1</a>'
  );
};
</script>
