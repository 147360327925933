<template>
  <ModalComponent
    :modal-active="gameplan_store.timeoutModal"
    :close="() => {}"
    :custom-class="'w-[25em]'"
  >
    <div class="text-center">
      <h1 class="text-2xl m-0.5 mb-3">Do you need more time?</h1>
      <p class="text-xs text-primary-600">
        It looks like you have not made any changes in the last 10 minutes. You
        have <span class="font-bold text-primary">{{ display }}</span> to click
        Continue before your Edit Mode session ends.
      </p>
      <div class="mt-[2em] text-left text-base text-gray-400">
        <div class="mt-5 flex justify-center">
          <SolidButton
            class="w-52"
            type="button"
            @click="
              () => {
                resetModal();
              }
            "
          >
            Continue
          </SolidButton>
        </div>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
import router from '@/router';
import ModalComponent from '@/components/ModalComponent.vue';
import SolidButton from '@/components/buttons/SolidButton.vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useIdle, useTimestamp } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

const gameplan_store = useGamePlanStore();

const IDLE_IN_SECONDS = 8 * 60;
const { idle, lastActive } = useIdle(IDLE_IN_SECONDS * 1000);

const now = useTimestamp({ interval: 1000 });

const idledFor = computed(() =>
  Math.floor((now.value - lastActive.value) / 1000)
);

watch(
  () => idle.value,
  () => {
    if (idle.value && !gameplan_store.timeoutModal && gameplan_store.editMode) {
      gameplan_store.timeoutModal = true;
      startCountdown(idledFor.value);
    }
  }
);

const minutes = ref(2);
const seconds = ref(0);
const display = ref('2:00');
let countdownInterval;

const resetModal = () => {
  gameplan_store.timeoutModal = false;
  minutes.value = 2;
  seconds.value = 0;
  display.value = '2:00';
  clearInterval(countdownInterval);
};

function startCountdown(idledFor) {
  let totalSeconds = minutes.value * 60 + seconds.value;

  totalSeconds = totalSeconds - Math.abs(idledFor - IDLE_IN_SECONDS);

  countdownInterval = setInterval(() => {
    const currentMinutes = Math.floor(totalSeconds / 60);
    const currentSeconds = totalSeconds % 60;

    minutes.value = currentMinutes;
    seconds.value = currentSeconds;
    display.value = `${minutes.value.toString().padStart(2)}:${seconds.value
      .toString()
      .padStart(2, '0')}`;

    if (totalSeconds <= 0) {
      clearInterval(countdownInterval);
      if (gameplan_store.timeoutModal == true) {
        gameplan_store.deleteDraftWorkStrategy().then(() => {
          gameplan_store.resetWorklists();
          resetModal();
          router.push(`/gameplan/`);
        });
      }
    }

    totalSeconds--;
  }, 1000); // Update every 1 second
}
</script>
