/**
 * Returns the passed path formatted for API calls.
 * @param {*} path
 * @param {$} override
 * @returns
 */
function getPath(path, override) {
  if (override) return `${override}${path}`;

  if (process.env.NODE_ENV === 'development') {
    path = window.location.origin.concat(['/'], path);
  } else {
    path = process.env.VUE_APP_INSURANCE_API_GATEWAY_ENDPOINT + path;
  }
  return path;
}

export default getPath;
