<template>
  <BaseButton
    :type="props.type"
    class="text-white border bg-delete border-delete hover:bg-delete-100 focus:z-10 focus:text-delete-700"
    :class="{
      [props.class]: true,
      'hover:bg-delete-hover': !props.disabled,
    }"
    :disabled="props.disabled"
  >
    <slot />
  </BaseButton>
</template>
<script setup>
import BaseButton from './BaseButton.vue';

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>
