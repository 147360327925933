<template>
  <table
    v-if="workItem_store.workItemTransactions.length > 0"
    class="table-auto w-full text-left border-0 drop-shadow-none"
  >
    <thead class="text-rev-sm sticky text-primary font-bold">
      <tr>
        <template v-for="(header, index) in TRANSACTIONS_HEADERS" :key="index">
          <TableHeader
            :id="header.key"
            :type="header.type"
            :offset="header.offset"
            :show-sort="true"
            :title="header.value"
            title-styling="text-primary font-bold"
            @sort="sort"
          />
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-show="workItem_store.isLoading">
        <td colspan="8">
          <div class="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-show="!workItem_store.isLoading" class="text-rev-sm text-gray-700">
      <tr
        v-for="(item, itemIndex) in workItem_store.workItemTransactions"
        :key="itemIndex"
        :data-index="itemIndex"
        :class="itemIndex % 2 === 1 ? 'bg-gray-100' : 'bg-white'"
      >
        <td
          v-for="(header, headerIndex) in TRANSACTIONS_HEADERS"
          :key="headerIndex"
        >
          <TableCellVue :item="item" :header="header" />
        </td>
      </tr>
    </tbody>
  </table>
  <div
    v-show="workItem_store.workItemTransactions.length === 0"
    class="flex bg-white h-36 p-2 justify-center items-center text-primary text-rev-base"
  >
    No transactions found
  </div>
  <VuePagination
    v-if="!workItem_store.isLoading"
    scroll-target="action-drop-down"
    :page="workItem_store.transactionsPage"
    local_store="transactions_pagination"
    type="Recent Transactions"
    :records="workItem_store.transactionsRecords"
    :limit="Number(workItem_store.transactionsLimit)"
    @paginate="paginate"
    @updateLimit="updateLimit"
  />
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import TableCellVue from '../../common/TableCellView.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import VuePagination from '@/views/VuePagination.vue';

const workItem_store = useWorkItemsStore();

const TRANSACTIONS_HEADERS = [
  {
    value: 'Posting Date',
    key: 'mod_timest',
    type: 'date',
    sort: true,
    width: '20%',
  },
  { value: 'Type', key: 'activity_type', sort: true, width: '20%' },
  { value: 'Code', key: 'activity_subtype', sort: true, width: '20%' },
  { value: 'Description', key: 'note', sort: true, width: '20%' },
  {
    value: 'Amount',
    key: 'amount',
    type: 'currency',
    sort: true,
    width: '20%',
  },
  {
    value: '',
    key: 'offset',
    width: '10%',
    offset: '2xl:min-w-[65px]',
  },
];

async function paginate(page) {
  await workItem_store.fetchWorkItemTransactions(
    props.workItemId,
    page,
    workItem_store.transactionsDirection,
    workItem_store.transactionsField
  );
}

async function updateLimit(limit) {
  workItem_store.transactionsLimit = limit.value;
  await workItem_store.fetchWorkItemTransactions(
    props.workItemId,
    1,
    workItem_store.transactionsDirection,
    workItem_store.transactionsField
  );
}

async function sort(direction, field) {
  await workItem_store.fetchWorkItemTransactions(
    props.workItemId,
    1,
    direction,
    field
  );
  workItem_store.transactionsDirection = direction;
  workItem_store.transactionsField = field;
  workItem_store.transactionsPage = 1;
}

const props = defineProps({
  workItemId: {
    type: String,
    default: '',
  },
});
</script>
<style scoped>
tr td {
  width: 25%;
}
</style>
