<template>
  <svg
    id="folder"
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    viewBox="0 0 39 39"
  >
    <path
      id="Path_1143"
      data-name="Path 1143"
      d="M36.563,4.875H21.938V2.438A2.437,2.437,0,0,0,19.5,0H2.438A2.437,2.437,0,0,0,0,2.438V36.563A2.436,2.436,0,0,0,2.438,39H36.563A2.436,2.436,0,0,0,39,36.563V7.313A2.436,2.436,0,0,0,36.563,4.875Z"
      fill="transparent"
    />
    <path
      id="Path_1193"
      data-name="Path 1193"
      d="M36.563,4.875H21.938V2.438A2.437,2.437,0,0,0,19.5,0H2.438A2.437,2.437,0,0,0,0,2.438V36.563A2.436,2.436,0,0,0,2.438,39H36.563A2.436,2.436,0,0,0,39,36.563V7.313A2.436,2.436,0,0,0,36.563,4.875Zm1.219,31.688a1.219,1.219,0,0,1-1.219,1.219H2.438a1.219,1.219,0,0,1-1.219-1.219V9.75H37.781Zm0-28.031H1.219V2.438A1.219,1.219,0,0,1,2.438,1.219H19.5a1.219,1.219,0,0,1,1.219,1.219V6.094H36.563a1.219,1.219,0,0,1,1.219,1.219Z"
      fill="currentColor"
    />
    <path
      id="Path_1144"
      data-name="Path 1144"
      d="M14.609,45.219H35.328a.609.609,0,0,0,0-1.219H14.609a.609.609,0,0,0,0,1.219Z"
      transform="translate(-5.469 -17.188)"
      fill="currentColor"
    />
    <path
      id="Path_1145"
      data-name="Path 1145"
      d="M14.609,39.219H35.328a.609.609,0,0,0,0-1.219H14.609a.609.609,0,0,0,0,1.219Z"
      transform="translate(-5.469 -14.844)"
      fill="currentColor"
    />
    <path
      id="Path_1146"
      data-name="Path 1146"
      d="M14.609,33.219h9.75a.609.609,0,0,0,0-1.219h-9.75a.609.609,0,0,0,0,1.219Z"
      transform="translate(-5.469 -12.5)"
      fill="currentColor"
    />
  </svg>
</template>
