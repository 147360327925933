<template>
  <PageView :not-found="users_store.notFound">
    <ModalComponent
      :modal-active="modalActive"
      :title="editWorklistId?.is_priority ? 'Edit Project' : 'Edit Worklist'"
      :close="toggleModal"
      :worklist="editWorklistId"
      :priority="editWorklistId?.is_priority"
      page="users"
    >
      <UpsertWorklistView
        :open="modalActive"
        :close="toggleModal"
        :worklist="editWorklistId"
        :priority="editWorklistId?.is_priority"
        page="users"
      />
    </ModalComponent>
    <ModalComponent
      :modal-active="selectedUserGoalModalActive"
      title="Edit User's Daily Goal"
      :close="toggleUserGoalModal"
    >
      <form :onsubmit="$event => setUsersGoal($event)">
        <BaseInput
          v-model="proxyDailyGoal"
          label="Daily Goal"
          type="number"
          :required="true"
          :autofocus="true"
          :min-num="0"
        />
        <div class="flex justify-end mt-5">
          <OutlinedButton
            class="w-16 mr-[1em]"
            @click="() => toggleUserGoalModal()"
          >
            Cancel
          </OutlinedButton>
          <SolidButton type="submit" class="w-16">Save</SolidButton>
        </div>
      </form>
    </ModalComponent>
    <div class="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="3"
        stroke="#265170"
        class="w-6 h-6 cursor-pointer"
        @click="hideSelectedUser"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        />
      </svg>
      <p class="text-rev-2xl text-primary font-bold ml-3">Back to Users</p>
    </div>
    <!-- User Description Component -->
    <div class="flex mt-4 bg-white p-4 shadow-xl overflow-auto">
      <div class="flex space-between justify-between w-full">
        <!-- User Image and Description -->
        <div
          v-show="!users_store.usersLoading && !userDetailLoading"
          class="flex items-center flex-1 mr-4"
        >
          <UserPicture
            :disable-popper="true"
            :user="users_store.getUserByUsername(selectedUser?.username)"
            :style="'text-[40px] min-w-[4rem] h-[4rem] font-bold mr-4'"
          />
          <div class="flex flex-col justify-center">
            <div class="text-rev-xl text-primary font-bold">
              {{ `${selectedUser?.first_name} ${selectedUser?.last_name}` }}
            </div>
            <div
              class="flex items-center text-rev-sm text-gray-700 font-normal flex-wrap"
            >
              <span>{{ selectedUser?.role_title }}</span>
              <span class="text-primary mx-3">●</span>
              <span>{{ selectedUser?.email }}</span>
            </div>
          </div>
        </div>
        <!-- User Stats -->
        <StatsGrid
          v-show="!users_store.usersLoading && !userDetailLoading"
          :user-details="true"
          :user-id="
            users_store.activeUser?.auxo_user_id != $route.params?.auxo_user_id
              ? selectedUser?.auxo_user_id
              : null
          "
          class="flex-1 justify-center"
        />
        <!-- User Daily Goals -->
        <div
          v-show="!users_store.usersLoading && !userDetailLoading"
          class="flex justify-center items-center flex-col flex-1"
        >
          <div>
            <div class="text-rev-xl font-medium text-primary whitespace-nowrap">
              Today's Goal
            </div>
            <div class="flex my-2 mx-1 items-center justify-center">
              <div v-show="!metrics_store.isLoading" class="mr-2">
                {{ dailyGoal }}
              </div>
              <img
                v-if="rbac_store.hasWriteRole('EDIT_USER_GOALS')"
                :src="EditIcon"
                class="h-4 cursor-pointer"
                @click="toggleUserGoalModal()"
              />
            </div>
          </div>
        </div>

        <div
          v-show="users_store.usersLoading || userDetailLoading"
          class="flex items-center justify-center w-full h-24"
        >
          <LoadingSpinner />
        </div>
      </div>
    </div>
    <!-- User Tabs - Worklists, History, Stats -->
    <div class="mt-8 shadow-xl text-rev-xl">
      <tabs
        ref="usersTabs"
        :options="{
          disableScrollBehavior: true,
          defaultTabHash: 'worklist-tab',
        }"
        :nav-item-active-class="'is-active font-bold'"
        :nav-item-inactive-class="'is-inactive font-normal'"
      >
        <tab id="worklist-tab" name="Worklists">
          <div class="w-full text-left border-0 bg-white drop-shadow-md">
            <WorklistTable
              :highlighted-row="highlightedRow"
              :username="deriveUsername()"
              :auxo-user-id="route.params?.auxo_user_id"
            />
          </div>
        </tab>
        <!-- We are temporarily hiding the Permissions tab -->
        <tab v-if="false" id="permissions-tab" name="Permissions">
          <div class="w-full text-left border-0 bg-white drop-shadow-md">
            <div class="h-32 flex justify-center items-center text-center">
              Permissions
            </div>
          </div>
        </tab>
        <!-- We are temporarily hiding the Hisory tab -->
        <tab v-if="false" id="history-tab" name="History">
          <div class="w-full text-left border-0 bg-white drop-shadow-md">
            <div class="h-32 flex justify-center items-center text-center">
              History
            </div>
          </div>
        </tab>
        <!-- We are temporarily hiding the Stats tab -->
        <tab
          v-if="hasViewUserProductivityPermission === true && false"
          id="stats-tab"
          name="Stats"
        >
          <div class="w-full text-left border-0 bg-white drop-shadow-md">
            <div class="h-32 flex justify-center items-center text-center">
              Stats
            </div>
          </div>
        </tab>
        <tab id="recent-activity-tab" name="Recent Activity">
          <div class="w-full text-left border-0 bg-white drop-shadow-md">
            <div class="justify-center items-center text-center">
              <RecentLeadTable
                :selected-row="selectedRow"
                :auxo_user_id="users_store.activeUser?.auxo_user_id.toString()"
                @setSelectedRow="setSelectedRow"
                @showDetails="showWorkListDetails"
              />
            </div>
          </div>
        </tab>
        <tab id="wip-list-tab" name="WIP List">
          <div class="w-full text-left border-0 bg-white drop-shadow-md">
            <div class="justify-center items-center text-center">
              <WipLeadTable
                :selected-row="selectedRow"
                :auxo_user_id="route.params.auxo_user_id"
                @setSelectedRow="setSelectedRow"
                @showDetails="setSelectedAssignmentId"
              />
            </div>
          </div>
        </tab>
      </tabs>
    </div>
  </PageView>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { useUsersStore } from '@/stores/useUsers';
import WorklistTable from '../worklists/WorklistTable.vue';
import { useWorklistsStore } from '@/stores/useWorklists';
import RecentLeadTable from '../lead/RecentLeadTable.vue';
import WipLeadTable from '../lead/WipLeadTable.vue';
import { useAssignmentsStore } from '@/stores/useAssignments';
import ModalComponent from '@/components/ModalComponent.vue';
import UpsertWorklistView from '@/views/worklists/UpsertWorklistView.vue';
import { useMetrics } from '@/stores/useMetrics';
import BaseInput from '@/components/forms/BaseInput.vue';
import StatsGrid from '../../components/StatsGrid.vue';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import EditIcon from '@/assets/edit.svg';
import PageView from '../../components/PageVue.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import UserPicture from '@/components/UserPicture.vue';
import { getUsername, formatUserRoles } from '@/utils/helpers';
import { useRbacStore } from '@/stores/useRbac';

// Pinia Stores
const worklists_store = useWorklistsStore();
const route = useRoute();
const users_store = useUsersStore();
const assignments_store = useAssignmentsStore();
const rbac_store = useRbacStore();

const activeUserTabIndex = ref(0);
const modalActive = ref(false);
const selectedUserGoalModalActive = ref(false);
const dailyGoal = ref(0);
const proxyDailyGoal = ref(null);
const editWorklistId = ref(null);
const metrics_store = useMetrics();
const highlightedRow = ref(null);
const selectedUser = ref(null);
const selectedRow = ref(null);
const usersTabs = ref(null);
const userDetailLoading = ref(false);

watch(
  route,
  () => {
    if (usersTabs.value) usersTabs.value.selectTab('#worklist-tab');
  },
  { deep: true, immediate: false }
);

metrics_store.$subscribe(() => {
  if (!route.params?.auxo_user_id) return;
  selectedUserGoalModalActive.value = false;
  dailyGoal.value = String(
    // check if the active user is the same as the user whose page you're on
    metrics_store.getGoal(
      route.params?.auxo_user_id == users_store?.activeUser?.auxo_user_id,
      'DailyGoal'
    ) ?? '0'
  );
  proxyDailyGoal.value = dailyGoal.value;
});

const hasViewUserProductivityPermission = ref(false);

const setSelectedRow = index => {
  selectedRow.value = index;
};

const setSelectedAssignmentId = assignmentId => {
  router.push({ path: `/lead/${assignmentId}` });
};

onMounted(async () => {
  const auxo_user_id = route.params?.auxo_user_id;
  if (!auxo_user_id) return;
  userDetailLoading.value = true;
  await metrics_store.getUserMetrics(auxo_user_id);
  await users_store
    .getUser(auxo_user_id)
    .then(user => {
      setSelectedUser(user);
    })
    .finally(() => {
      userDetailLoading.value = false;
    });
  await assignments_store.fetchRecentUserAssignments(auxo_user_id);
  await worklists_store.fetchUserWorklists(auxo_user_id);
  await assignments_store.fetchUserWip(auxo_user_id);
});

onUnmounted(() => {
  worklists_store.userWorklists = [];
  userDetailLoading.value = false;
});

const deriveUsername = () => {
  const name = getUsername(selectedUser.value?.email);
  return name;
};

watch(route, async to => {
  const auxo_user_id = to.params?.auxo_user_id;
  if (!auxo_user_id) return;
  await users_store
    .getUser(route.params?.auxo_user_id)
    .then(user => setSelectedUser(user));
  await metrics_store.getUserMetrics(auxo_user_id);
  await assignments_store.fetchRecentUserAssignments(
    users_store.activeUser?.auxo_user_id
  );
  await worklists_store.fetchUserWorklists(auxo_user_id);
});

async function setUsersGoal(e) {
  e.preventDefault();
  dailyGoal.value = proxyDailyGoal.value;
  const auxo_user_id = getCurrentOrSelectedUserID();
  await metrics_store.setUserGoal(auxo_user_id, dailyGoal.value);
  await metrics_store.getUserMetrics(auxo_user_id);
}

function getCurrentOrSelectedUserID() {
  return users_store.activeUser?.auxo_user_id != route.params?.auxo_user_id
    ? selectedUser.value?.auxo_user_id
    : users_store.activeUser.auxo_user_id;
}

async function setSelectedUser(user) {
  if (selectedUser.value?.auxo_user_id === user?.auxo_user_id) {
    router.push({ path: `/users/${user.auxo_user_id}` });
  } else {
    selectedUser.value = {
      ...user,
      role_title: formatUserRoles(user.user_roles),
    };
  }
}

function hideSelectedUser() {
  router.push(`/users`);
  activeUserTabIndex.value = 0;
}

function showWorkListDetails(worklistId) {
  router.push({ path: `/lead/${worklistId}` });
}

function toggleModal() {
  modalActive.value = !modalActive.value;
  editWorklistId.value = null;
}

function toggleUserGoalModal() {
  selectedUserGoalModalActive.value = !selectedUserGoalModalActive.value;
  proxyDailyGoal.value = dailyGoal.value;
}
</script>

<style scoped></style>
