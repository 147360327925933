<template>
  <div class="not-found">
    <h1>404 error</h1>
    <p>This page doesn't exist.</p>
  </div>
</template>

<script setup></script>
<style scoped>
.not-found {
  @apply text-center;
}

.not-found h1 {
  @apply text-6xl font-thin pb-5;
}

.not-found p {
  @apply text-2xl;
}
</style>
