<template>
  <div class="inline-flex rounded-md shadow-sm my-2 mt-3">
    <button
      class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-2 border-r-0 border-gray-200 rounded-l-lg rounded-r-none hover:bg-gray-100 focus:z-10"
      type="button"
      @click="emits('showSetRules', true)"
    >
      Set Rules
    </button>

    <button
      class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-2 border-r-0 border-gray-200 rounded-l-none rounded-r-none hover:bg-gray-100 focus:z-10"
      type="button"
      @click="emits('showGetRules', true)"
    >
      Get Rules
    </button>
    <button
      class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-2 border-r-0 border-gray-200 rounded-l-none rounded-r-none hover:bg-gray-100 focus:z-10"
      type="button"
      @click="
        () => {
          const ruleValidity = queryBuilder.validateRules();
          queryBuilder.setAttempt(true);

          const ruleMessage = `Rules are ${
            ruleValidity ? 'valid' : 'invalid'
          }.`;
          console.debug(ruleMessage);
          if (ruleValidity) toast.success(ruleMessage);
          else toast.error(ruleMessage);

          const worklistValidity = queryBuilder.validateWorklist(
            route.name === 'editWorklist'
          );
          queryBuilder.setAttempt(true);

          const wlMessage = `Worklist fields are ${
            worklistValidity ? 'valid' : 'invalid'
          }.`;
          console.debug(wlMessage);
          if (worklistValidity) toast.success(wlMessage);
          else toast.error(wlMessage);

          if (ruleValidity && worklistValidity) {
            console.warn('Save not implemented.');
          }
        }
      "
    >
      Validate Inputs
    </button>
    <button
      class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-2 border-r-0 border-gray-200 rounded-l-none rounded-r-none hover:bg-gray-100 focus:z-10"
      type="button"
      @click="
        () => {
          gameplan_store.testCriteria(queryBuilder.query);
        }
      "
    >
      Test Criteria
    </button>
    <button
      class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-2 border-r-0 border-gray-200 rounded-l-none rounded-r-none hover:bg-gray-100 focus:z-10"
      type="button"
      @click="
        () => {
          if (route.name === 'editWorklist') {
            let valid = queryBuilder.validateWorklist(true);
            console.log(valid);
          } else if (route.name == 'newWorklist') {
            let valid = queryBuilder.validateWorklist(false);
            console.log(valid);
          }
        }
      "
    >
      Validate Worklist
    </button>
    <button
      class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-2 border-gray-200 rounded-r-md rounded-l-none hover:bg-gray-100 focus:z-10"
      type="button"
      @click="
        () => {
          if (route.name === 'editWorklist') {
            gameplan_store.editWorklist(
              queryBuilder.worklist,
              queryBuilder.query
            );
          } else if (route.name == 'newWorklist') {
            gameplan_store.saveWorklist(
              queryBuilder.worklist,
              queryBuilder.query
            );
          }
        }
      "
    >
      Save Worklist
    </button>
  </div>
</template>

<script setup>
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

const queryBuilder = useQueryBuilderStore();
const emits = defineEmits(['showGetRules', 'showSetRules']);
const gameplan_store = useGamePlanStore();
const route = useRoute();
</script>

<style scoped></style>
