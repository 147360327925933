<template>
  <div
    :id="`${header.key}-table-cell`"
    :class="['flex', numericHeaders.includes(header.type) ? 'mr-[12px]' : '']"
  >
    <span
      v-if="header?.type === 'date'"
      :class="header?.source !== 'lead' ? 'flex justify-center w-full' : ''"
    >
      <div>
        {{
          item[header.key] &&
          // This is making sure that the date given is not the default constant that is stored as END_OF_DAYS in the store
          // The T00:00:00 is required for the check, otherwise there will be some offset even if it is the same date
          new Date(item[header.key] + 'T00:00:00').getTime() !=
            new Date(gameplan_store.endOfDays).getTime()
            ? formatDate(
                item[header.key],
                header.format,
                header.truncateTimezone
              )
            : ''
        }}
      </div>
    </span>
    <span v-else-if="header?.key === 'is_priority'">
      <template v-if="'deactivated' in item">
        <img
          v-if="item.deactivated == true"
          title="Deactivated"
          :src="YellowExclamationPoint"
          class="h-[32px] !w-[200px]"
        />
        <img
          v-else-if="item[header.key]"
          title="Priority"
          :src="PriorityLong"
        />
      </template>
      <template v-else>
        <img
          v-if="item[header.key] && header?.source === 'lead'"
          title="Priority"
          :src="PriorityLong"
        />
        <img v-else-if="item[header.key]" title="Priority" :src="Priority" />
      </template>
    </span>
    <span
      v-else-if="centeredHeaders.includes(header?.type)"
      class="flex justify-center w-full"
    >
      <img
        v-if="isEditingRuleRank"
        :src="MoveRowIcon"
        class="cursor-pointer w-3"
        :draggable="false"
      />
      <div v-else-if="editMode">
        <BaseInput
          v-model="input"
          wrapper-class="mt-none"
          :disabled="item.is_default"
          class="rounded text-center"
          :blur="e => gameplan_store.reorder(e, item, input, index)"
          :min-num="1"
          :step="'1'"
          @keyup.enter="e => gameplan_store.reorder(e, item, input, index)"
        />
      </div>
      <span v-else>{{ item[header.key] }}</span>
    </span>
    <span
      v-else-if="header?.type === 'count'"
      class="flex flex-row-reverse w-full"
    >
      {{ item[header.key] ? item[header.key] : 0 }}</span
    >
    <span
      v-else-if="header?.type === 'currency'"
      class="flex flex-row-reverse w-full"
    >
      {{
        item[header.key]
          ? formatCurrency().format(item[header.key] || 0)
          : '$0.00'
      }}</span
    >
    <span
      v-else-if="header?.key === 'dropdown'"
      class="flex justify-center w-full"
    >
      <font-awesome-icon :icon="selected ? faChevronDown : faChevronRight" />
    </span>
    <span
      v-else-if="header?.key === 'work_list_assignee'"
      class="flex justify-center w-full"
    >
      <UserGroup :users="sortUsers(item?.assignees || [], users_store)" />
    </span>
    <span v-else-if="header?.key === 'move'">
      <img
        v-show="!item.is_default"
        :src="MoveRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
      />
    </span>
    <span v-else-if="header?.key === 'copy'">
      <img
        v-show="!item.is_default"
        :src="CopyRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
        @click="
          e => {
            e.stopPropagation();

            queryBuilder.resetQuery();
            queryBuilder.resetWorklist();

            queryBuilder.worklist = {
              work_list_name: item.work_list_name,
              description: item.description,
              owner: item.owner,
              assignees: item.assignees,
              is_priority: item.is_priority,
              work_list_name: `${item.work_list_name} (Copy)`,
              start_timest: null,
              end_timest: null,
            };

            queryBuilder.defaultWorklist = false;

            queryBuilder.query = cloneDeep(item.criteria_json);
            router.push(`/gameplan/new`);
          }
        "
      />
    </span>
    <span v-else-if="header?.key === 'edit'">
      <img
        :src="EditRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
        @click="
          e => {
            e.stopPropagation();

            queryBuilder.resetQuery();
            queryBuilder.resetWorklist();

            queryBuilder.worklist = {
              work_list_id: item.work_list_id,
              work_list_name: item.work_list_name,
              description: item.description,
              owner: item.owner,
              mod_by: item.mod_by,
              mod_timest: item.mod_timest,
              assignees: item.assignees ?? [],
              start_timest: item.start_timest,
              end_timest: item.end_timest,
              is_priority: item.is_priority,
            };

            queryBuilder.defaultWorklist = item?.is_default || false;

            queryBuilder.query = item.criteria_json;
            router.push(`/gameplan/edit`);
          }
        "
      />
    </span>
    <span v-else-if="header?.key === 'trash'">
      <img
        v-show="!item.is_default"
        :src="TrashRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
        @click="
          e => {
            e.stopPropagation();
            gameplan_store.deleteModal = item;
            queryBuilder.defaultWorklist = false;
          }
        "
      />
    </span>
    <span v-else-if="header?.key === 'edited'" class="flex justify-center">
      <font-awesome-icon
        v-if="item[header.key]"
        class="text-cyan-500/100 h-3"
        :icon="faCircle"
      />
    </span>
    <span v-else :class="{ truncate: header.key != 'note' }">{{
      item[header.key]
    }}</span>
  </div>
</template>

<script setup>
import { formatCurrency, formatDate } from '@/utils/helpers';
import MoveRowIcon from '@/assets/move-row-icon.svg';
import YellowExclamationPoint from '@/assets/yellow_exclamation_point.svg';
import Priority from '@/assets/priority.svg';
import PriorityLong from '@/assets/priority_long.svg';
import CopyRowIcon from '@/assets/copy.svg';
import EditRowIcon from '@/assets/edit.svg';
import TrashRowIcon from '@/assets/trashcan.svg';
import BaseInput from '../../components/forms/BaseInput.vue';
import { watch } from 'vue';
import router from '@/router';
import UserGroup from '@/components/UserGroup.vue';
import { sortUsers } from '@/utils/helpers';
import cloneDeep from 'lodash/cloneDeep';

import { numericHeaders, centeredHeaders } from '../../utils/constants';
import {
  faChevronDown,
  faChevronRight,
  faCircle,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { ref } from 'vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { useUsersStore } from '@/stores/useUsers';
const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  header: {
    type: Object,
    default: () => {},
  },
  isEditingRuleRank: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  edit: {
    default: undefined,
    type: Boolean,
  },
  editMode: {
    default: false,
    type: Boolean,
  },
  index: {
    default: null,
    type: Number,
  },
});

const gameplan_store = useGamePlanStore();
const queryBuilder = useQueryBuilderStore();
const users_store = useUsersStore();

const input = ref(String(props.item[props.header.key]));

watch(
  () => props.item,
  () => {
    input.value = String(props.item[props.header.key]);
  }
);
</script>
