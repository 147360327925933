<template>
  <div class="flex w-full h-full bg-primary-100">
    <!-- Logo Container -->
    <div class="flex flex-1 place-items-center justify-center bg-primary">
      <img :src="logoImage" class="w-2/3" />
    </div>
    <!-- Logout Confirmation Container -->
    <div
      class="flex flex-1 flex-col justify-center place-items-center text-center bg-white"
    >
      <div
        class="flex flex-1 items-center justify-center px-24 py-24 bg-white w-2/3"
      >
        <p
          class="text-[2.5vw] text-primary font-bold w-auto text-center text-balance"
        >
          You've successfully logged out!
        </p>
      </div>
      <SupportPopper class="pb-8" />
    </div>
  </div>
</template>

<script setup>
import logoImage from '@/assets/auxo-White-3C.png';
import SupportPopper from '@/components/SupportPopper.vue';
import { onMounted } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import * as helpers from '@/utils/helpers';
import { getActivePinia } from 'pinia';
import { useMetrics } from '@/stores/useMetrics';
import { useWebSockets } from '@/stores/useWebSockets';
import { useUsersStore } from '@/stores/useUsers';

onMounted(() => {
  const metrics_store = useMetrics();
  const websockets_store = useWebSockets();
  const users_store = useUsersStore();
  try {
    websockets_store.close();
    helpers.logUserAnalytics(users_store, metrics_store, null);
  } catch (err) {
    console.error(err);
  } finally {
    // getActivePinia get's an array of proxies for each store, calls reset on each
    getActivePinia()._s.forEach(store => store.$reset());
  }
});

onBeforeRouteLeave(() => {
  return false;
});
</script>
