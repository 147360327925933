import { defineStore } from 'pinia';
import * as helpers from '@/utils/helpers';
import { END_OF_DAYS } from './useGamePlans';
import { isSameDay, parseISO } from 'date-fns';
import { apiGet } from '@/utils/api';
import getPath from '@/utils/getPath';
import { objectPick } from '@vueuse/core';

export const useWorklistsStore = defineStore('worklists', {
  state: () => ({
    worklists: [],
    payers: [],
    isLoading: false,
  }),
  getters: {
    sortWorklists: state => {
      return (order, field, priority = false) => {
        if (priority)
          return helpers.sort(order, field, state.priority_worklists);

        return helpers.sort(order, field, state.worklists);
      };
    },
    sortUserWorklists: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.userWorklists);
      };
    },
    getWorklistName: state => {
      return id => {
        return state.worklists
          .concat(state.priority_worklists)
          .find(worklist => {
            return worklist.work_list_id === id;
          })?.work_list_name;
      };
    },
    getWorklistById: state => {
      return (id, priority = false) => {
        if (priority)
          return state.priority_worklists.find(worklist => {
            return worklist.work_list_id === id;
          });

        return state.worklists.find(worklist => {
          return worklist.work_list_id === id;
        });
      };
    },
    getUserWorklistById: state => {
      return id => {
        return state.userWorklists.find(worklist => {
          return worklist.work_list_id === id;
        });
      };
    },
    getWorkItemCount: state => {
      return (id, priority = false) => {
        if (priority === true)
          return state.priority_worklists.find(
            worklist => worklist.work_list_id === id
          )?.work_item_count;

        return state.worklists.find(worklist => worklist.work_list_id === id)
          ?.work_item_count;
      };
    },
    getWorklistDropdownOptions: state => {
      return (priority = false) => {
        if (priority) {
          return state.priority_worklists.map(worklist => {
            return {
              key: worklist.work_list_id,
              value: worklist.work_list_name,
            };
          });
        }

        return state.worklists.map(worklist => {
          return { key: worklist.work_list_id, value: worklist.work_list_name };
        });
      };
    },

    getPayersDropdownOptions: state => {
      return state.payers.map(payer => {
        return {
          key: payer.payer_name,
          value: payer.payer_name,
          ...payer,
        };
      });
    },
  },
  actions: {
    async fetchUserWorklists(auxo_user_id) {
      try {
        this.isLoading = true;

        const response = await apiGet(
          getPath(`users/${auxo_user_id}/worklists`)
        );
        this.worklists =
          response?.data.map(wl => {
            return {
              ...wl,
              assignees: wl.assignees?.filter(e => e),
              end_timest:
                wl.end_timest &&
                !isSameDay(
                  parseISO(wl.end_timest.slice(0, 10)),
                  parseISO(END_OF_DAYS)
                )
                  ? wl.end_timest
                  : null,
              // This is for keeping track of items that are different from the database
              edited: false,
            };
          }) || [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? err);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchPayers() {
      try {
        const responseData = await apiGet(getPath('lookups/payer'));

        this.payers = responseData?.data || [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? err);
      }
    },
  },
});
