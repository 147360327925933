<template>
  <div
    :class="{
      error: props.error,
      'focused-input': props.focused,
      'unfocused-input': !props.focused,
      'rsp-input': true,
    }"
  >
    <v-select
      :model-value="rsp"
      :options="rspLookup_store.getRspOptions"
      multiple
      :filter="searchRsp"
      :close-on-select="false"
      :deselect-from-dropdown="true"
      :clear-search-on-select="false"
      :disabled="props.disabled"
      :map-keydown="keydownHandlers"
      :dropdown-should-open="
        ({ noDrop, open, mutableLoading }) => {
          if (props.disabled) return false;
          if (open) setOpen(true);
          else setOpen(false);
          return noDrop ? false : (open && !mutableLoading) || focused;
        }
      "
      @update:modelValue="rsp => updateVal(rsp)"
    >
      <!-- https://vue-select.org/api/slots.html#selected-option-container -->
      <template
        #selected-option-container="{
          option,
          multiple,
          disabled: isDisabled,
          deselect,
        }"
      >
        <div v-show="showSelectedOption(option)" class="vs__selected">
          {{ option.child_label || option.label }}
          <button
            v-if="multiple && !props.disabled"
            ref="deselectButtons"
            :disabled="isDisabled"
            type="button"
            class="vs__deselect"
            :title="`Deselect ${option.child_label || option.label}`"
            :aria-label="`Deselect ${option.child_label || option.label}`"
            @click="
              () => {
                deselect(option);
              }
            "
          >
            <img :src="CloseIcon2" />
          </button>
        </div>
      </template>

      <template #option="option">
        <div
          :style="{
            'margin-left': `${option.level * 2}em`,
            display: hideOption(option) ? 'none' : null,
            padding: '3px 20px',
          }"
        >
          <button
            v-if="option.child_ids.length > 1"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();

                if (collapsed.includes(option.value.rsp_id)) {
                  collapsed = collapsed.filter(e => e != option.value.rsp_id);
                } else {
                  collapsed = [...collapsed, option.value.rsp_id];
                }
              }
            "
          >
            <img
              :src="DownArrowSVG"
              class="h-3 mr-1 rotate-0"
              :class="{
                '!-rotate-90': isCollapsed(option),
              }"
            />
          </button>
          <input
            :id="`option-id-${option.value.rsp_id}`"
            type="checkbox"
            class="pointer-events-none"
            :checked="isChecked(option) == true"
            :indeterminate="isIndeterminate(option) == true"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            "
          />
          {{ option.label }}
        </div>
      </template>
      <template #open-indicator="{ attributes }">
        <button
          v-if="rsp?.length && !props.disabled"
          @click="
            e => {
              e.preventDefault();
              e.stopPropagation();
              reset();
              return false;
            }
          "
        >
          <img :src="CloseIconSVG" class="w-4 h-4 mr-1" />
        </button>

        <img
          :src="DownArrowSVG"
          v-bind="attributes"
          class="h-3 cursor-pointer ml-1"
        />
      </template>
    </v-select>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRspLookupStore } from '@/stores/useRspLookup';
import DownArrowSVG from '@/assets/down-arrow.svg';
import CloseIconSVG from '@/assets/close-icon.svg';
import CloseIcon2 from '@/assets/close-icon-2.svg';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';

const MAX_DEPTH = 3;

const rsp = ref([]);
const collapsed = ref([]);

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
  setValue: {
    type: Function,
    default: () => {},
  },
});

// Watch parent query builder to update state
watch(
  () => props.value,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    else {
      const newMapping = uniq(
        props.value
          ?.map(el =>
            rspLookup_store.getRspOptions.find(
              option => option.value.rsp_id === el.rsp_id
            )
          )
          .flatMap(option =>
            option.child_ids.map(child =>
              rspLookup_store.getRspOptions.find(
                el => el.value.rsp_id === child
              )
            )
          )
          .filter(e => e),
        e => e.value.rsp_id
      );

      const oldMapping = rsp.value;

      if (isEqual(newMapping, oldMapping)) return;
      else rsp.value = newMapping;
    }
  }
);

// Reset component state
const reset = () => {
  rsp.value = [];
  collapsed.value = [];
  props.setValue([]);
};

// Is a checkbox checked
const isChecked = option => {
  return (
    rsp.value.findIndex(el => el.value.rsp_id == option.value.rsp_id) !== -1
  );
};

// Is a checkbox indeterminate
const isIndeterminate = option => {
  return (
    option.child_ids.some(
      child => rsp.value.findIndex(el => el.value.rsp_id == child) !== -1
    ) && isChecked(option) !== true
  );
};

// Search all branches of the tree on a match, using parent_ids
function searchRsp(options, search) {
  const matchingRspIds = options
    .filter(el => {
      if (el.label.toLowerCase().trim().includes(search.toLowerCase().trim()))
        return true;
    })
    .flatMap(el => [el.value.rsp_id].concat(el.parent_ids));

  const newOptions = options.filter(el => {
    if (matchingRspIds.includes(el.value.rsp_id)) {
      // If matching option was collapsed, uncollapse
      if (collapsed.value.includes(el.value.rsp_id))
        collapsed.value = collapsed.value.filter(e => e != el.value.rsp_id);
      return true;
    }
  });

  return newOptions;
}

// Is a chevron collapsed
const isCollapsed = option => {
  return collapsed.value.includes(option.value.rsp_id);
};

// Returns true if an option is collapsed
const hideOption = option => {
  return collapsed.value.find(el => option.parent_ids.includes(el));
};

// Returns true if a selected should be shown
const showSelectedOption = option => {
  const parentIds = option.parent_ids.filter(el => el != option.value.rsp_id);

  const parentIsChecked =
    rsp.value.findIndex(el => parentIds.includes(el.value.rsp_id)) !== -1;

  return !parentIsChecked;
};

/**
 * updateVal is called on a change of the selected options, and dictate how rsp.value
 * will update.
 *
 * @param {*} options All selected options
 */
function updateVal(options) {
  const previousValues = rsp.value;
  const newValues = options;

  // Remove child values from deselected parent nodes
  function getFilteredOptions(options) {
    const optionsToRemove = previousValues
      .filter(
        el => !newValues.map(el => el.value.rsp_id).includes(el.value.rsp_id)
      )
      .flatMap(val => val.child_ids.concat(val.parent_ids));

    return options
      .flatMap(option =>
        option.child_ids
          .map(child =>
            rspLookup_store.getRspOptions.find(el => el.value.rsp_id === child)
          )
          .filter(e => e)
      )
      .filter(el => !optionsToRemove.includes(el.value.rsp_id));
  }

  // Add parent vales from selected child nodes (when all are selected)
  function getParentParentSelections(options) {
    let tempOptions = options;

    function getParentSelectionLevel(options) {
      return rspLookup_store.getRspOptions.filter(parent => {
        // Get all child ids non-inclusive off self
        const childIds = parent.child_ids.filter(
          el => el != parent.value.rsp_id
        );

        // If all child ids exist in value return true
        return (
          parent.level <= 1 &&
          childIds.every(el =>
            getFilteredOptions(options).find(
              selection => selection.value.rsp_id == el
            )
          )
        );
      });
    }

    for (let i = 0; i < MAX_DEPTH - 1; i++) {
      tempOptions = [...tempOptions, ...getParentSelectionLevel(tempOptions)];
    }

    return tempOptions;
  }

  const filteredOptions = getFilteredOptions(options);

  // Update rsp.value, check uniqueness
  rsp.value = uniq(
    [...filteredOptions, ...getParentParentSelections(filteredOptions)],
    el => el.value.rsp_id
  );

  props.setValue(
    rsp.value.filter(el => showSelectedOption(el)).map(el => el.value)
  );
}

function keydownHandlers(map) {
  return {
    ...map,
    //  delete, we prevent this.maybeDeleteValue()
    8: () => {},

    //  tab
    9: () => this.onTab(),

    //  enter
    13: e => {
      e.preventDefault();
      return this.typeAheadSelect();
    },

    //  esc
    27: () => this.onEscape(),

    //  up
    38: e => {
      e.preventDefault();
      return this.typeAheadUp();
    },

    //  down
    40: e => {
      e.preventDefault();
      return this.typeAheadDown();
    },
  };
}

const rspLookup_store = useRspLookupStore();
</script>

<style>
.focused-input .vs__selected-options,
.focused-input .vs__dropdown-toggle {
  height: inherit !important;
}

.unfocused-input .vs__selected-options,
.unfocused-input .vs__dropdown-toggle {
  height: 36px !important;
  overflow: hidden;
}

.rsp-input .vs__dropdown-option {
  padding: 0px;
}

.rsp-input .vs__selected {
  height: fit-content;
}
</style>
