<template>
  <div
    class="flex flex-col z-10 xl:max-w-[350px] xl:min-w-[350px] 2xl:min-w-[575px] 2xl:max-w-[575px]"
  >
    <!-- Quick View  -->
    <div
      class="w-full mr-2 mb-5 p-2 overflow-visible scrollbar-hide bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <h1 class="ml-2 text-rev-xl text-primary font-semibold">Quick View</h1>

        <div
          class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid grid-cols-1 whitespace-nowrap sm:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2"
        >
          <template v-for="(field, index) in QUICK_VIEW" :key="field.val">
            <div
              :class="`flex flex-row my-1 items-center justify-between sm:justify-normal xl:justify-between 2xl:justify-normal ${availableQVClasses[index]}`"
            >
              <div :class="index % 2 === 0 ? 'w-36' : 'w-24'">
                <p
                  class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded w-fit text-rev-sm"
                >
                  {{ field.title }}
                </p>
              </div>
              <div>
                <!-- https://github.com/valgeirb/vue3-popper -->
                <Popper :hover="true" :locked="true" placement="right">
                  <p
                    class="bg-white whitespace-normal max-w-[150px] text-rev-sm text-gray-700"
                  >
                    {{ getWorkItemDetail(field) }}
                  </p>
                  <template #content>
                    <div
                      slot="reference"
                      class="bg-white px-2 rounded font-thin text-xs shadow whitespace-normal max-w-[250px]"
                    >
                      {{ getWorkItemDetail(field) }}
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Transaction Summary  -->
    <div
      v-if="workItems_store?.workItem?.workflow_status !== 'Inactive'"
      class="w-full mr-2 mb-5 p-2 overflow-visible overflow-y-scroll scrollbar-hide bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <div
          class="flex items-center w-full cursor-pointer"
          @click="expandTransactionSummary = !expandTransactionSummary"
        >
          <h1 class="ml-2 text-rev-xl font-semibold">Transaction Summary</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4 ml-2"
            :class="expandTransactionSummary === true && 'rotate-180'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid">
          <!-- Transaction Summary: Charges -->
          <div
            v-if="expandTransactionSummary"
            class="flex items-center justify-between my-1 w-56 xl:w-full 2xl:w-56 h-auto"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="
                  !expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'
                "
              >
                Charges
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'"
            >
              {{
                formatCurrency().format(workItems_store.workItemChargesTotal)
              }}
            </p>
          </div>

          <!-- Transaction Summary: Payments -->
          <div
            v-if="expandTransactionSummary"
            class="flex items-center justify-between my-1 w-56 xl:w-full 2xl:w-56 h-auto"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="!expandTransactionSummary ? 'text-lg' : 'text-rev-sm'"
              >
                Payments
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-xl' : 'text-rev-sm'"
            >
              {{
                formatCurrency().format(workItems_store.workItemPaymentsTotal)
              }}
            </p>
          </div>

          <!-- Transaction Summary: Adjustments -->
          <div
            v-if="expandTransactionSummary"
            class="flex items-center justify-between my-1 w-56 xl:w-full 2xl:w-56 h-auto"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="!expandTransactionSummary ? 'text-lg' : 'text-rev-sm'"
              >
                Adjustments
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-xl' : 'text-rev-sm'"
            >
              {{
                formatCurrency().format(
                  workItems_store.workItemAdjustmentsTotal
                )
              }}
            </p>
          </div>

          <!-- Transaction Summary: Balance -->
          <div
            class="flex items-center justify-between my-1 w-56 xl:w-full 2xl:w-56 h-auto"
          >
            <div class="min-w-[75px]">
              <p
                class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded mr-2 w-fit"
                :class="
                  !expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'
                "
              >
                Balance
              </p>
            </div>
            <p
              class="bg-white text-ellipsis overflow-hidden mr-2 w-fit"
              :class="!expandTransactionSummary ? 'text-rev-sm' : 'text-rev-sm'"
            >
              {{
                getWorkItemDetail({ val: 'current_balance', type: 'currency' })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Payer  -->
    <div
      class="w-full mr-2 mb-5 p-2 overflow-visible bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <div
          class="flex items-center w-full cursor-pointer"
          @click="expandPayer = !expandPayer"
        >
          <h1 class="ml-2 text-rev-xl font-semibold">Payer</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4 ml-2"
            :class="expandPayer === true && 'rotate-180'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div
          class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid grid-cols-1 whitespace-nowrap sm:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2"
        >
          <template v-for="(field, index) in PAYER" :key="field.val">
            <div
              v-if="!field.hide ? true : expandPayer"
              :class="`flex flex-row my-1 items-center justify-between sm:justify-normal xl:justify-between 2xl:justify-normal ${availablePayerClasses[index]}`"
            >
              <div
                :class="`${index % 2 === 0 ? 'w-36' : 'w-28'} xl:w-fit ${
                  index % 2 === 0 ? '2xl:w-36' : '2xl:w-28'
                }`"
              >
                <p
                  class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded w-fit text-rev-sm"
                >
                  {{ field.title }}
                </p>
              </div>

              <!-- https://github.com/valgeirb/vue3-popper -->
              <Popper :hover="true" :locked="true" placement="right">
                <p
                  class="bg-white whitespace-normal mr-2 max-w-full text-rev-sm text-right md:text-left xl:text-right 2xl:text-left 2xl:max-w-[100px]"
                >
                  {{ getWorkItemDetail(field) }}
                </p>
                <template #content>
                  <div
                    slot="reference"
                    class="bg-white px-2 rounded font-thin text-xs shadow"
                  >
                    {{ getWorkItemDetail(field) }}
                  </div>
                </template>
              </Popper>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Provider  -->
    <div
      class="w-full mr-2 p-2 overflow-visible bg-white drop-shadow-md rounded"
    >
      <div class="flex flex-col">
        <div
          class="flex items-center w-full cursor-pointer"
          @click="expandProvider = !expandProvider"
        >
          <h1 class="ml-2 text-rev-xl font-semibold">Provider</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2.5"
            stroke="currentColor"
            class="w-4 h-4 ml-2"
            :class="expandProvider === true && 'rotate-180'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>

        <div
          class="bg-white text-gray-700 p-2 rounded-b text-xs h-fit grid grid-cols-1 whitespace-nowrap sm:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2"
        >
          <template v-for="(field, index) in PROVIDER" :key="field.val">
            <div
              v-if="!field.hide ? true : expandProvider"
              :class="`flex flex-row my-1 items-center justify-between sm:justify-normal xl:justify-between 2xl:justify-normal ${availableProviderClasses[index]}`"
            >
              <div :class="index % 2 === 0 ? 'w-36' : 'w-28'">
                <p
                  class="whitespace-nowrap bg-gray-100 p-1 font-semibold text-gray-700 rounded w-fit text-rev-sm"
                >
                  {{ field.title }}
                </p>
              </div>

              <!-- https://github.com/valgeirb/vue3-popper -->
              <Popper :hover="true" placement="right">
                <p
                  class="bg-white whitespace-normal mr-2 max-w-full text-rev-sm text-right md:text-left xl:text-right 2xl:text-left 2xl:max-w-[100px]"
                >
                  {{ getWorkItemDetail(field) }}
                </p>
                <template #content>
                  <div
                    slot="reference"
                    class="bg-white px-2 rounded font-thin text-xs shadow"
                  >
                    {{ getWorkItemDetail(field) }}
                  </div>
                </template>
              </Popper>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref } from 'vue';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { useRoute } from 'vue-router';
import Popper from 'vue3-popper';
import { formatCurrency, formatDate } from '@/utils/helpers';

const QUICK_VIEW = [
  { title: 'Account #', val: 'account_number' },
  { title: 'Visit #', val: 'visit_number' },
  { title: 'Medical Record #', val: 'medical_record_number' },
  { title: 'Claim #', val: 'claim_number' },
  { title: 'DOB', val: 'patient_dob', type: 'date' },
  { title: 'Phase', val: 'phase' },
  { title: 'Service Start Date', val: 'service_start_date', type: 'date' },
  { title: 'Status', val: 'status' },
  { title: 'Service End Date', val: 'service_end_date', type: 'date' },
  { title: 'Reason', val: 'reason' },
  { title: 'Score', val: 'score', type: 'round' },
  { title: 'Worklist', val: 'work_list_name' },
];

const PAYER = [
  { title: 'Current Plan', val: 'current_plan_name' },
  { title: 'Policy #', val: 'policy_number' },
  { title: 'Member', val: 'subscriber_name' },
  { title: 'Group #', val: 'group_number' },
  { title: 'Primary Payer', val: 'current_payer_name', hide: true },
  { title: 'Primary Plan', val: 'original_plan_name', hide: true },
  {
    title: 'Coverage Start',
    val: 'coverage_start_date',
    hide: true,
    type: 'date',
  },
  { title: 'Coverage End', val: 'coverage_end_date', hide: true, type: 'date' },
];

const PROVIDER = [
  { title: 'Visit Type', val: 'visit_type' },
  { title: 'Department', val: 'department' },
  { title: 'Billing Provider', val: 'billing_provider' },
  { title: 'Provider NPI', val: 'billing_provider_npi' },
  { title: 'Billing Type', val: 'billing_type', hide: true },
  { title: 'Location', val: 'location', hide: true },
  { title: 'Billing Entity', val: 'billing_entity', hide: true },
  { title: 'Tax ID', val: 'tax_id', hide: true },
];

const route = useRoute();

watch(route, async () => {
  // reset all to expanded
  expandPayer.value = true;
  expandProvider.value = true;
  expandTransactionSummary.value = true;
});

const workItems_store = useWorkItemsStore();

const expandTransactionSummary = ref(true);
const expandPayer = ref(true);
const expandProvider = ref(true);

const getWorkItemDetail = field => {
  const value = workItems_store.workItem?.[field.val];

  if (field.type === 'date') {
    return value ? formatDate(value) : '';
  } else if (field.type === 'round') {
    return value ? Math.round(value) : '';
  } else if (field.type === 'currency') {
    return value ? formatCurrency().format(value) : '';
  }

  return value;
};

//This seems really silly but it was the only way I could get tailwind to cooperate
//It needs to know about the classes at build time and doing it via the index dynamically happens after build
const availableQVClasses = [
  'xl:order-0 2xl:order-0',
  'xl:order-2 2xl:order-1',
  'xl:order-1 2xl:order-2',
  'xl:order-3 2xl:order-3',
  'xl:order-4 2xl:order-4',
  'xl:order-8 2xl:order-5',
  'xl:order-5 2xl:order-6',
  'xl:order-9 2xl:order-7',
  'xl:order-6 2xl:order-8',
  'xl:order-10 2xl:order-9',
  'xl:order-7 2xl:order-10',
  'xl:order-11 2xl:order-11',
];

const availablePayerClasses = [
  'xl:order-0 2xl:order-0',
  'xl:order-2 2xl:order-1',
  'xl:order-1 2xl:order-2',
  'xl:order-3 2xl:order-3',
  'xl:order-4 2xl:order-4',
  'xl:order-6 2xl:order-5',
  'xl:order-6 2xl:order-6',
  'xl:order-7 2xl:order-7',
];

const availableProviderClasses = [
  'xl:order-0 2xl:order-0',
  'xl:order-1 2xl:order-1',
  'xl:order-3 2xl:order-2',
  'xl:order-2 2xl:order-3',
  'xl:order-4 2xl:order-4',
  'xl:order-6 2xl:order-5',
  'xl:order-5 2xl:order-6',
  'xl:order-7 2xl:order-7',
];
</script>

<style>
.fill-height {
  height: 700px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
