// From CPRD-820, create pattern for websockets in the frontend
// For ready state and other websocket specific built in APIs checkout
// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState

import { toast } from 'vue3-toastify';
import { defineStore } from 'pinia';
import { useGamePlanStore } from './useGamePlans';
import { useUsersStore } from './useUsers';
import { useRbacStore } from './useRbac';
import { useWorkItemsStore } from './useWorkItems';
import router from '@/router';
import { getUserInitials } from '@/utils/helpers';
import { getIdToken } from '@/utils/auth';

export const WEBSOCKET_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const useWebSockets = defineStore('useWebSockets', {
  state: () => ({
    connection: undefined,
    token: undefined,
    baseURL: process.env.VUE_APP_WEB_SOCKET_API_GATEWAY_ENDPOINT,
    backoff: 2000, // use for exponential backoff when trying to reconnect on error
    loading: false,
    ping: undefined,
    INTERVAL: 5 * 60 * 1000,
    MAX_BACKOFF: 1 * 60 * 1000,
  }),
  getters: {
    getEditGamePlan() {
      return this.editGamePlan;
    },
    /* Returns true if loading, connecting, or connected */
    checkConnection() {
      // 0/1 means connecting/connected
      if (
        this.connection?.readyState == 0 ||
        this.connection?.readyState == 1 ||
        this.loading
      ) {
        return true;
      }
      return false;
    },
  },
  actions: {
    async websocketEventRouter(message) {
      const rbac_store = useRbacStore();
      const gameplan_store = useGamePlanStore();
      const workItems_store = useWorkItemsStore();
      const users_store = useUsersStore();
      const game_plan_events = [
        'work_strategy_locked',
        'draft_work_strategy_saved',
        'work_strategy_unlocked',
        'work_strategy_updated',
        'adm_draft_ws_canceled',
      ];
      const claim_status_events = [
        'claim_status_published',
        'claim_status_not_available',
      ];
      const user_events = ['auxo_user_deactivated', 'auxo_user_photo_updated'];

      const key = message?.event_type;

      if (
        game_plan_events.includes(key) &&
        rbac_store.hasWriteRole('EDIT_GAMEPLAN')
      ) {
        // Game Plan Related Events
        if (
          key == 'work_strategy_unlocked' ||
          key == 'work_strategy_updated' ||
          key == 'adm_draft_ws_canceled'
        ) {
          gameplan_store.setLockStatusAndMessage(false);
          gameplan_store.resetWorklists();
        }

        if (key == 'work_strategy_locked') {
          gameplan_store.setLockStatusAndMessage(
            true,
            `${message.payload.owner} is currently editing the Game Plan.`
          );
        }

        if (key == 'draft_work_strategy_saved') {
          gameplan_store.setLockStatusAndMessage(
            true,
            `The Game Plan strategy is being recalculated. Edit Mode is unavailable.`
          );
        }
      } else if (claim_status_events.includes(key)) {
        // Claim Status Related Events
        if (key == 'claim_status_published') {
          if (
            router.currentRoute.value.fullPath ===
            `/lead/${message?.payload.work_item_id}`
          ) {
            workItems_store.isClaimStatusLoading = false;
            workItems_store.fetchWorkItemActivities(
              message?.payload.work_item_id
            );
          }
        }

        if (key == 'claim_status_not_available') {
          if (
            router.currentRoute.value.fullPath ===
            `/lead/${message?.payload.work_item_id}`
          ) {
            workItems_store.isClaimStatusLoading = false;
            toast.error(`Claim Status check not available, try again later`);
          }
        }
      } else if (user_events.includes(key)) {
        // User Management Related Events
        if (key == 'auxo_user_deactivated') {
          users_store.logOut();
        }
        if (key === 'auxo_user_photo_updated') {
          const user_detail = await users_store.getUserByEmail(
            users_store.activeUser.email
          );
          users_store.getAllUsers();
          users_store.activeUser.photo_url = user_detail.photo_url || 'none';
          users_store.activeUser.initials = getUserInitials(user_detail);
        }
      }
    },
    async keepalive() {
      const message = {
        action: 'ping',
      };
      this.ping = setInterval(() => {
        try {
          this.connection.send(JSON.stringify(message));
        } catch {
          console.debug('error');
        }
      }, this.INTERVAL);
    },
    async reconnect() {
      this.backoff = Number(this.backoff) * 2;
      clearInterval(this.ping);
      console.debug('Attempting to reconnect.');
      console.debug('Backoff set to: ' + this.backoff);
      console.debug('The connection url is: ' + this.baseURL);
      await this.connect();
    },
    async connect() {
      if (this.checkConnection) return;
      this.loading = true;

      setTimeout(async () => {
        const idToken = await getIdToken();

        const url = this.baseURL + '?idToken=' + idToken;
        const ws = new WebSocket(url);

        ws.onmessage = ev => {
          const message = JSON.parse(ev?.data);
          console.debug(message);
          this.websocketEventRouter(message);
        };

        ws.onerror = err => {
          console.error(`${JSON.stringify(err)}`);
          // throw err;
        };

        ws.onopen = ev => {
          console.debug('Socket is now open.', ev);

          this.loading = false;
          this.connection = ws;
          this.keepalive();
          this.backoff = 2000;
        };

        ws.onclose = ev => {
          console.debug('WebSocket connection closed:', ev);
          console.debug('Close code:', ev.code);
          console.debug('Close reason:', ev.reason);

          switch (ev.code) {
            case 1005: // Closed no status (logging out)
              break;
            default:
              this.loading = false;
              this.reconnect();
          }
        };
      }, Number(Math.min(this.backoff, this.MAX_BACKOFF)));
    },
    async close() {
      console.debug('Closing websocket connection.');
      this.loading = false;
      this.connection?.close();
      this.connection = undefined;
    },
  },
});
