<template>
  <svg
    aria-hidden="true"
    class="w-6 h-6 fill-gray-400 transition duration-75 group-hover:fill-white"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3729 2H13.4317C13.4317 1.46957 13.2251 0.960859 12.8574 0.585786C12.4897 0.210714 11.9909 0 11.4709 0H8.52974C8.0097 0 7.51097 0.210714 7.14325 0.585786C6.77554 0.960859 6.56895 1.46957 6.56895 2H3.62778C3.10774 2 2.60901 2.21071 2.24129 2.58579C1.87357 2.96086 1.66699 3.46957 1.66699 4V18C1.66699 18.5304 1.87357 19.0391 2.24129 19.4142C2.60901 19.7893 3.10774 20 3.62778 20H16.3729C16.8929 20 17.3916 19.7893 17.7594 19.4142C18.1271 19.0391 18.3337 18.5304 18.3337 18V4C18.3337 3.46957 18.1271 2.96086 17.7594 2.58579C17.3916 2.21071 16.8929 2 16.3729 2ZM11.4709 2V5H8.52974V2H11.4709ZM13.4317 15H6.56895C6.30894 15 6.05957 14.8946 5.87571 14.7071C5.69185 14.5196 5.58856 14.2652 5.58856 14C5.58856 13.7348 5.69185 13.4804 5.87571 13.2929C6.05957 13.1054 6.30894 13 6.56895 13H13.4317C13.6917 13 13.9411 13.1054 14.1249 13.2929C14.3088 13.4804 14.4121 13.7348 14.4121 14C14.4121 14.2652 14.3088 14.5196 14.1249 14.7071C13.9411 14.8946 13.6917 15 13.4317 15ZM13.4317 11H6.56895C6.30894 11 6.05957 10.8946 5.87571 10.7071C5.69185 10.5196 5.58856 10.2652 5.58856 10C5.58856 9.73478 5.69185 9.48043 5.87571 9.29289C6.05957 9.10536 6.30894 9 6.56895 9H13.4317C13.6917 9 13.9411 9.10536 14.1249 9.29289C14.3088 9.48043 14.4121 9.73478 14.4121 10C14.4121 10.2652 14.3088 10.5196 14.1249 10.7071C13.9411 10.8946 13.6917 11 13.4317 11Z"
    />
  </svg>
</template>
