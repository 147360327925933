<template>
  <ModalComponentFlowBite
    modal_id="search-modal"
    :custom-style="{
      display: 'block',
      minWidth: '80%',
      justifyContent: 'flex-start',
    }"
  >
    <div class="relative p-4 bg-white rounded-lg shadow sm:p-5">
      <div class="search-bar">
        <!-- Search Bar & result count -->
        <div class="flex flex-row items-center">
          <BaseInputFB
            id="search-modal-input"
            v-model="search_store.input"
            placeholder="Search Work Items"
            class="text-primary rounded-l-lg rounded-r-none my-2"
            :class="{
              'bg-red-50 border border-red-500 text-red-900 ': disableInput,
            }"
            wrapper-class="w-[98%] my-2"
            :autocomplete="'off'"
            @keyup="event => handleKeyUpAndSearch(event)"
          />
          <button
            class="bg-transparent border-1 rounded-r-lg my-2 flex items-center justify-center"
            @click="search()"
          >
            <SearchIcon />
          </button>
          <button @click="() => closeSearchModal()">
            <img class="h-[20px] w-[20px] mx-2" :src="CloseIcon2" />
          </button>
        </div>
        <p v-if="disableInput" class="text-sm text-red-600 dark:text-red-500">
          Only 100 characters allowed count: {{ search_store.input.length }} /
          100
        </p>
      </div>
      <!-- Results Table -->
      <div
        class="flex-col justify-center relative overflow-x-auto shadow-md sm:rounded-t-lg"
      >
        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-visible"
        >
          <thead
            class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <template v-for="(header, index) in HEADERS" :key="index">
                <TableHeader
                  :id="header.key"
                  :title="header.value"
                  :show-sort="header.sort"
                  :width="header.width"
                  :type="header.type"
                  @sort="sortSearchResults"
                />
              </template>
            </tr>
          </thead>
          <tbody>
            <!-- No results yet, user hasn't inputted -->
            <tr
              v-if="
                search_store.searchResults.length === 0 &&
                !search_store.isSearching
              "
            >
              <td
                class="justify-center items-center text-center h-[80px]"
                colspan="100%"
              >
                No Results
              </td>
            </tr>

            <!-- results loading -->
            <tr
              v-if="
                search_store.searchResults.length === 0 &&
                search_store.isSearching
              "
            >
              <td colspan="100%">
                <div class="flex items-center justify-center">
                  <LoadingSpinner />
                </div>
              </td>
            </tr>

            <!-- Results loaded, render actual table -->
            <template
              v-for="(result, index) in search_store.searchResults"
              :key="index"
            >
              <tr
                :id="result.revology_system_number"
                class="cursor-pointer"
                :class="getRowClass(result, index)"
                @click="redirect(result.revology_system_number)"
                @mouseenter="
                  () => {
                    focusedRowIndex = index;
                    focusRow();
                  }
                "
                @mouseleave="removeFocus()"
              >
                <td
                  id="patient_name"
                  scope="row"
                  class="font-medium text-gray-900 whitespace-nowrap"
                >
                  {{ result.patient_name }}
                </td>

                <td id="account_number" scope="row">
                  {{ result.account_number }}
                </td>
                <td id="medical_record_number" scope="row">
                  {{ result.medical_record_number }}
                </td>
                <td id="visit_number" scope="row">
                  {{ result.visit_number }}
                </td>
                <td id="claim_number" scope="row">
                  {{ result.claim_number }}
                </td>
                <td id="billing_type" scope="row">
                  {{ result.billing_type }}
                </td>
                <td id="service_start_date" scope="row">
                  {{ formatDate(result.service_start_date) }}
                </td>
                <td id="service_end_date" scope="row">
                  {{ formatDate(result.service_end_date) }}
                </td>

                <td id="workflow_status" scope="row">
                  {{ result.workflow_status }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- Pagination -->
      <div class="flex flex-col items-center mt-3">
        <PaginationFlowBite
          v-if="
            search_store.searchResults.length !== 0 &&
            search_store.searchRecords > search_store.searchLimit - 1
          "
          :records="search_store.searchRecords"
          type="Search Results"
          :limit="Number(search_store.searchLimit)"
          :page="search_store.searchPage"
          :use-arrow-keylisteners="true"
          @paginate="paginate"
        />
      </div>
    </div>
  </ModalComponentFlowBite>

  <!-- Search Icon in Title Bar -->
  <div
    v-if="showIcon && rbac_store.hasWriteRole('WORKITEM_SEARCH')"
    @click="
      () => {
        search_store.clear();
        search_store.showSearchModal();
      }
    "
  >
    <img src="@/assets/MagnifyGlass.svg" class="h-10 cursor-pointer p-1 mr-2" />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, onUpdated, computed } from 'vue';
import router from '@/router';
import ModalComponentFlowBite from './ModalComponentFlowBite.vue';
import { useDebounceFn } from '@vueuse/core';
import { useSearchStore } from '@/stores/useSearch';
import { formatDate } from '@/utils/helpers';
import BaseInputFB from './forms/BaseInputFlowBite.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import { useRbacStore } from '@/stores/useRbac';
import { Modal } from 'flowbite';
import { ref } from 'vue';

import SearchIcon from '@/assets/sidebar-icons/SearchIcon.vue';
import PaginationFlowBite from '@/views/PaginationFlowBite.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import CloseIcon2 from '@/assets/close-icon-2.svg';

const props = defineProps({
  showMetrics: {
    default: () => {},
    type: Function,
  },
  showIcon: {
    default: true,
    type: Boolean,
  },
});

const search_store = useSearchStore();
const rbac_store = useRbacStore();

const searchModal = ref(null);

const focusedRow = ref(null);
const focusedRowIndex = ref(-1);
// Watches user input, if its greater then 100 characters tells input
// to error (red border)
const disableInput = computed(() => {
  if (search_store.input.length > 100) {
    return true;
  }
  return false;
});
const HEADERS = [
  { key: 'patient_name', value: 'Patient Name', sort: true },
  { key: 'account_number', value: 'Account', sort: true },
  { key: 'medical_record_number', value: 'Medical Record', sort: true },
  { key: 'visit_number', value: 'Visit', sort: true },
  { key: 'claim_number', value: 'Claim', sort: true },
  {
    key: 'billing_type',
    value: 'Billing Type',
    sort: true,
  },
  {
    key: 'service_start_date',
    value: 'Service Start Date',
    sort: true,
    format: 'Pp',
    truncateTimezone: false,
  },
  {
    key: 'service_end_date',
    value: 'Service End Date',
    sort: true,
    format: 'Pp',
    truncateTimezone: false,
  },
  { key: 'workflow_status', value: 'Status', sort: true },
];

/** search modal & event listeners set up */
onMounted(async () => {
  const options = {
    backdrop: 'static',
  };

  const instanceOptions = {
    id: 'search-modal',
    override: true,
  };
  searchModal.value = new Modal(
    document.getElementById('search-modal'),
    options,
    instanceOptions
  );

  // everytime modal is shown focus search is called
  searchModal.value._options.onShow = focusSearch;
  search_store.searchModalControls = searchModal.value;

  // if we just returned from a search show the modal
  if (search_store.returningFromPreviousSearch === true) {
    searchModal.value.show();
    search_store.returningFromPreviousSearch = false;
  }

  // set up event listeners
  window.addEventListener('keydown', handleArrowKeyPress);
});

// searchModal.value._options.onShow = focusSearch;
// this func is called everytime modal is opened
// helps with render timing and ensuring the input exists to focus
const focusSearch = async () => {
  const inputElement = document.getElementById('search-modal-input');
  inputElement.focus();
};

onUnmounted(() => {
  window.removeEventListener('keydown', handleArrowKeyPress);
});

onUpdated(() => {
  props.showMetrics(false);
});

/** event listeners for pagination, selecting a row, and redirection
 * focusedRowIndex value increases or decreases, calls focusRow()
 * each <tr> re-renders by calling getRowClass()
 */
const handleArrowKeyPress = event => {
  let minBoundry = focusedRowIndex.value > 0;
  let maxBoundry =
    focusedRowIndex.value < search_store.searchResults.length - 1;

  if (event.key === 'ArrowUp' && minBoundry) {
    event.preventDefault();
    focusedRowIndex.value -= 1;
    focusRow();
  } else if (event.key === 'ArrowDown' && maxBoundry) {
    event.preventDefault();
    focusedRowIndex.value += 1;
    focusRow();
  } else if (event.key === 'Enter' || event.key === 'Return') {
    if (focusedRow.value) {
      redirect(focusedRow.value.id);
    }
  }
};

/** sets focusedRow to html el user has navigated to with arrow keys */
const focusRow = () => {
  focusedRow.value = null;
  // each table rows id is the workitems RSN, grab it and focus
  let newRSN =
    search_store.searchResults[focusedRowIndex.value]?.revology_system_number;
  focusedRow.value = document.getElementById(newRSN);
};

/** resets user keyboard nav */
const removeFocus = () => {
  focusedRowIndex.value = -1;
  focusedRow.value = null;
};

/**
 * Determines if row is currently being focused by user
 * Whether that is through arrow keys or by mouse
 *
 * On mouse enter of any <tr> it is set to focusedIndex -- see template <tr>
 */
const getRowClass = (result, index) => {
  // user has navigated to this row
  if (result.revology_system_number.toString() === focusedRow.value?.id) {
    return 'bg-gray-200 text-gray-900'; // mocks hover
  }
  // every other row follow normal stripe pattern
  return index % 2 === 0 ? 'bg-white' : 'bg-gray-50'; // striped rows
};

/** Search Functions
 * handleKeyUpAndSearch - ignores arrow key presses - calls searchDebounce
 * searchDebounce - ensures new searches aren't spammed waits 2sec before querying again
 * search, paginate, and sort actually make the search calls
 */
const handleKeyUpAndSearch = event => {
  const ignoredKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
  if (event.key == 'Enter' || event.key == 'Return') {
    search();
    return;
  }
  if (!ignoredKeys.includes(event.key)) {
    searchDebounce();
  }
};

async function search() {
  if (disableInput.value === true) return;
  removeFocus();
  await search_store.queryBySearchTerm();
}

/** creates auto search functionallity */
const searchDebounce = useDebounceFn(() => {
  if (!search_store.isSearching) {
    search();
  }
}, 2000);

async function paginate(page) {
  removeFocus();
  await search_store.queryBySearchTerm(
    search_store.input,
    page,
    search_store.searchDirection,
    search_store.searchField
  );
}

async function sortSearchResults(direction, field) {
  search_store.searchDirection = direction;
  search_store.searchField = field;
  search_store.searchPage = 1;
  await search_store.queryBySearchTerm(search_store.input, 1, direction, field);
  removeFocus();
}
/** ------------ */

function redirect(path) {
  router.push(`/lead/${path}`);
  search_store.hideSearchModal();
  search_store.setJustSearched(true);
}

const closeSearchModal = () => {
  search_store.clear();
  search_store.hideSearchModal();
};
</script>

<style scoped></style>
