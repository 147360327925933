<template>
  <div
    :class="{
      error: props.error,
      'focused-input': props.focused,
      'unfocused-input': !props.focused,
      'multiselect-input': true,
    }"
  >
    <v-select
      v-model="multiVal"
      label="lookup_value"
      :reduce="el => el?.lookup_value || el"
      multiple
      :close-on-select="false"
      :options="options"
      :disabled="props.disabled"
      :clear-search-on-select="false"
      :dropdown-should-open="
        ({ noDrop, open, mutableLoading }) => {
          if (props.disabled) return false;
          if (open) setOpen(true);
          else setOpen(false);
          return noDrop ? false : (open && !mutableLoading) || focused;
        }
      "
      @search="loadOptions"
      @update:modelValue="vals => props.setValue(vals)"
      @option:selecting="
        selectedOption => {
          // Manually Remove a selection
          if (multiVal)
            multiVal = multiVal.filter(e => e != selectedOption.lookup_value);
        }
      "
    >
      <template
        #selected-option-container="{
          option,
          multiple,
          disabled: isDisabled,
          deselect,
        }"
      >
        <div class="vs__selected">
          {{ option.lookup_value }}
          <button
            v-if="multiple && !props.disabled"
            ref="deselectButtons"
            :disabled="isDisabled"
            type="button"
            class="vs__deselect"
            :title="`Deselect ${option.lookup_value}`"
            :aria-label="`Deselect ${option.lookup_value}`"
            @click="
              () => {
                if (option.lookup_type) {
                  deselect(option);
                } else {
                  deselect(multiVal.find(el => el == option.lookup_value));
                }
              }
            "
          >
            <img :src="CloseIcon2" />
          </button>
        </div>
      </template>
      <template #option="option">
        <div
          :class="{
            checked: isChecked(option) == true,
          }"
          @click="
            () => {
              if (isChecked(option)) {
                const newValue = [
                  ...multiVal.filter(e => e != option.lookup_value),
                ];

                multiVal.value = newValue;
              }
            }
          "
        >
          <input
            :id="`option-id-${option.lookup_value}`"
            type="checkbox"
            class="pointer-events-none"
            :checked="isChecked(option) == true"
            @click="
              e => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            "
          />
          {{ option.lookup_value }}
        </div>
      </template>
      <template #open-indicator="{ attributes }">
        <button
          v-if="multiVal?.length && !props.disabled"
          @click="
            e => {
              e.preventDefault();
              e.stopPropagation();
              reset();
              return false;
            }
          "
        >
          <img :src="CloseIconSVG" class="w-4 h-4 mr-1" />
        </button>

        <img
          v-show="options.length"
          :src="DownArrowSVG"
          v-bind="attributes"
          class="h-3 cursor-pointer ml-1"
        />
      </template>
      <template #no-options="{ search }">
        <div v-if="search.length === 0">Search</div>
        <div v-else>
          <div v-if="selectLoading">Loading...</div>
          <div v-else>No values found.</div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import debounce from 'lodash/debounce';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import DownArrowSVG from '@/assets/down-arrow.svg';
import CloseIconSVG from '@/assets/close-icon.svg';
import isEqual from 'lodash/isEqual';
import CloseIcon2 from '@/assets/close-icon-2.svg';

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  setOpen: {
    type: Function,
    default: () => {},
  },
  setValue: {
    type: Function,
    default: () => {},
  },
  rule: {
    type: Object,
    default: () => {},
  },
});

const queryBuilder_store = useQueryBuilderStore();

const multiVal = ref([]);
const options = ref([]);
const selectLoading = ref(false);
const searchValue = ref('');

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    else {
      multiVal.value = newValue;
    }
  }
);

watch(
  () => props.rule.operator,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    else {
      reset();
    }
  }
);

watch(multiVal, () => {
  props.setValue(multiVal);
});

// Reset component state
const reset = () => {
  multiVal.value = [];
  options.value = [];
  props.setValue([]);
};

// Is a checkbox checked
const isChecked = option => {
  if (multiVal.value)
    return multiVal.value.findIndex(el => el === option.lookup_value) !== -1;
};

const search_debounce = debounce(async search => {
  if (!search) return;
  const path = props.rule.field + '/' + search;
  const response = await queryBuilder_store.getFieldValues(path);
  options.value = response;
  selectLoading.value = false;
}, 250);

async function loadOptions(search) {
  selectLoading.value = true;
  searchValue.value = search;
  search_debounce(search);
}

watch(searchValue, () => {
  if (!searchValue.value) {
    options.value = [];
  }
});
</script>

<style>
.focused-input .vs__selected-options,
.focused-input .vs__dropdown-toggle {
  height: inherit !important;
}

.unfocused-input .vs__selected-options,
.unfocused-input .vs__dropdown-toggle {
  height: 36px !important;
  overflow: hidden;
}

.multiselect-input .vs__selected {
  height: fit-content;
}

.multiselect-input .vs__dropdown-option {
  padding: 0;
}

.multiselect-input .vs__dropdown-option > div {
  padding: 3px 20px;
}

.multiselect-input .vs__dropdown-option > div.checked:hover {
  background: var(--vs-dropdown-option--deselect-bg);
  color: white;
}
</style>
