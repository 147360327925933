<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="33"
    viewBox="0 0 40 33"
  >
    <g id="id-card" transform="translate(0.5 -4.5)">
      <path
        id="Path_1152"
        data-name="Path 1152"
        d="M36.563,8.556H31.688V5.593A.6.6,0,0,0,31.078,5H27.422a.6.6,0,0,0-.609.593V8.556H12.188V5.593A.6.6,0,0,0,11.578,5H7.922a.6.6,0,0,0-.609.593V8.556H2.438A2.4,2.4,0,0,0,0,10.926v23.7A2.4,2.4,0,0,0,2.438,37H36.563A2.4,2.4,0,0,0,39,34.63v-23.7A2.4,2.4,0,0,0,36.563,8.556Z"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
      />
      <path
        id="Path_1195"
        data-name="Path 1195"
        d="M36.563,8.556H31.688V5.593A.6.6,0,0,0,31.078,5H27.422a.6.6,0,0,0-.609.593V8.556H12.188V5.593A.6.6,0,0,0,11.578,5H7.922a.6.6,0,0,0-.609.593V8.556H2.438A2.4,2.4,0,0,0,0,10.926v23.7A2.4,2.4,0,0,0,2.438,37H36.563A2.4,2.4,0,0,0,39,34.63v-23.7A2.4,2.4,0,0,0,36.563,8.556Zm-8.531-2.37h2.438v4.741H28.031Zm-19.5,0h2.438v4.741H8.531ZM37.781,34.63a1.2,1.2,0,0,1-1.219,1.185H2.438A1.2,1.2,0,0,1,1.219,34.63v-23.7A1.2,1.2,0,0,1,2.438,9.741H7.313v1.778a.6.6,0,0,0,.609.593h3.656a.6.6,0,0,0,.609-.593V9.741H26.813v1.778a.6.6,0,0,0,.609.593h3.656a.6.6,0,0,0,.609-.593V9.741h4.875a1.2,1.2,0,0,1,1.219,1.185Z"
        fill="currentColor"
      />
      <path
        id="Path_1153"
        data-name="Path 1153"
        d="M40.609,38.219H46.7A.609.609,0,0,0,46.7,37H40.609a.609.609,0,0,0,0,1.219Z"
        transform="translate(-15.625 -13.058)"
        fill="currentColor"
      />
      <path
        id="Path_1154"
        data-name="Path 1154"
        d="M49.141,31H40.609a.609.609,0,0,0,0,1.219h8.531a.609.609,0,0,0,0-1.219Z"
        transform="translate(-15.625 -10.609)"
        fill="currentColor"
      />
      <path
        id="Path_1155"
        data-name="Path 1155"
        d="M49.141,43H40.609a.609.609,0,0,0,0,1.219h8.531a.609.609,0,0,0,0-1.219Z"
        transform="translate(-15.625 -15.506)"
        fill="currentColor"
      />
      <path
        id="Path_1156"
        data-name="Path 1156"
        d="M40.609,26.219h3.656a.609.609,0,0,0,0-1.219H40.609a.609.609,0,0,0,0,1.219Z"
        transform="translate(-15.625 -8.161)"
        fill="currentColor"
      />
      <path
        id="Path_1157"
        data-name="Path 1157"
        d="M16.531,21a8.531,8.531,0,1,0,8.531,8.531A8.532,8.532,0,0,0,16.531,21ZM11.974,35.244a5.316,5.316,0,0,1,4.558-2.056,5.318,5.318,0,0,1,4.558,2.056,7.291,7.291,0,0,1-9.116,0Zm10-.844a6.52,6.52,0,0,0-5.445-2.431A6.52,6.52,0,0,0,11.086,34.4a7.313,7.313,0,1,1,10.891,0Z"
        transform="translate(-3.125 -6.808)"
        fill="currentColor"
      />
      <path
        id="Path_1158"
        data-name="Path 1158"
        d="M23.094,31.266V30.047a3.047,3.047,0,0,0-6.094,0v1.219a3.047,3.047,0,0,0,6.094,0Zm-1.219,0a1.828,1.828,0,0,1-3.656,0V30.047a1.828,1.828,0,0,1,3.656,0Z"
        transform="translate(-6.641 -9.068)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
