<template>
  <div class="sm:col-span-2">
    <label
      class="block mb-2 text-sm font-medium text-primary pointer-events-none"
      :class="{
        'text-red-700': props.error,
      }"
      for="file_input"
    >
      Upload Avatar
    </label>
    <div class="flex items-center w-full">
      <div class="relative inline-block">
        <button
          type="button"
          class="text-white bg-gray-100 font-medium rounded-full text-sm p-[24px] text-center inline-flex items-center me-2 absolute top-0 left-0 opacity-0 hover:opacity-90 transition-opacity z-50"
          :class="{ hidden: localUserDetail.photo_url === 'none' }"
          @click="$emit('avatarRemoved')"
        >
          <svg
            class="w-[35px] h-[35px] text-red-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.6"
              d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
            />
          </svg>
          <span class="sr-only">Icon description</span>
        </button>

        <UserPicture
          :user="localUserDetail"
          :disable-popper="true"
          :style="
            'text-[32px] w-20 h-20' + localUserDetail
              ? '!w-[80px] !h-[80px] max-w-[80px]'
              : ''
          "
          class="relative top-[1px] left-[1px] z-10"
        />
      </div>

      <div class="w-full pl-3 mt-4">
        <label
          class="custom-file-upload flex items-center justify-start w-full h-12 text-sm bg-gray-50 rounded-lg border border-gray-300 cursor-pointer pointer-events-auto focus:outline-none"
        >
          <span
            class="bg-primary text-white text-l font-medium px-8 py-[14px] rounded-l-lg"
            tabindex="7"
            role="button"
            aria-label="Choose File"
            onkeydown="if(event.key === ' ' || event.key === 'Spacebar') { event.preventDefault(); document.getElementById('file_input').click(); }"
            >Choose File</span
          >

          <input
            id="file_input"
            class="hidden"
            aria-describedby="file_input_help"
            type="file"
            accept=".jpg,.png"
            @change="handleFileChange"
          />
          <span class="pl-6 text-gray-500">
            {{ props.chosenFileName }}
          </span>
        </label>
        <p id="file_input_help" class="mt-1 text-xs font-normal text-gray-500">
          Custom profile photo is optional. Accepted file types: JPG or PNG. Max
          file size of 800 KB.
        </p>
      </div>
    </div>
    <p
      v-if="props.error || props.showTempMessage"
      class="mt-2 text-sm text-red-600 dark:text-red-500"
    >
      <span class="font-medium">Oops!</span> {{ props.errorMessage }}
    </p>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useAdminStore } from '@/stores/useAdmin';
import UserPicture from '@/components/UserPicture.vue';
import isEqual from 'lodash/isEqual';

const emit = defineEmits([
  'avatarUploaded',
  'avatarRemoved',
  'fileSelected',
  'fileSizeError',
  'fileUploadError',
]);

const adminStore = useAdminStore();

const props = defineProps({
  userDetailProp: {
    default: () => {},
    type: Object,
  },
  chosenFileName: {
    default: '',
    type: String,
  },
  error: {
    default: false,
    type: Boolean,
  },
  errorMessage: {
    default: '',
    type: String,
  },
  showTempMessage: {
    default: false,
    type: Boolean,
  },
});

const localUserDetail = ref(props.userDetailProp);

// If there is no photo then dynamically update the user initials
watch(
  () => props.userDetailProp,
  (newValue, oldValue) => {
    if (
      !isEqual(newValue, oldValue) &&
      localUserDetail.value.photo_url === 'none'
    ) {
      localUserDetail.value = newValue;
    }
    if (!isEqual(newValue.photo_url, oldValue.photo_url)) {
      localUserDetail.value.photo_url = newValue.photo_url;
      localUserDetail.value.initials = newValue.initials;
    }
  }
);

// If there is no file upload then use initials for avatar
watch(
  () => props.chosenFileName,
  newValue => {
    if (newValue == 'No file chosen') {
      localUserDetail.value = props.userDetailProp;
    }
  }
);

async function handleFileChange(e) {
  const reader = new FileReader();
  let targetFile = e.target.files[0];

  if (targetFile.size / 1024 > 800) {
    emit('fileSizeError');
    return;
  }

  emit('fileSelected', targetFile.name.substring(0, 50));

  reader.onload = async () => {
    const response = await adminStore.uploadAvatar(reader.result);

    if (response) {
      emit('avatarUploaded', response.photo_url);
      localUserDetail.value.photo_url = response.photo_url;
    } else {
      emit('fileSelected', 'No File Chosen');
      emit('fileUploadError');
    }
  };

  reader.readAsDataURL(targetFile);
  e.target.value = null;
}
</script>
