<template>
  <div class="flex items-center m-0.5 p-1" :class="props.class">
    <input
      id="checkbox"
      type="checkbox"
      :disabled="props.disabled"
      class="border-2 rounded p-2 mr-1"
      :checked="props.modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label for="checkbox" class="font-bold p-0.5 text-gray-500">{{
      label
    }}</label>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    default: '',
    type: String,
  },
  modelValue: {
    default: false,
    type: Boolean,
  },
  class: {
    default: '',
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
});
defineEmits(['update:modelValue']);
</script>
