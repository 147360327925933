<template>
  <button
    type="button"
    :data-tooltip-target="`tooltip-${props.for}`"
    data-tooltip-style="dark"
    class="ml-1"
    tabindex="-1"
  >
    <img
      class="w-4 h-4 text-gray-400 cursor-pointer hover:text-gray-900"
      :src="QuestionMarkFilledIn"
    />
  </button>
  <div
    :id="`tooltip-${props.for}`"
    role="tooltip"
    class="inline-block border-solid border-2 border-gray-100 absolute invisible z-10 py-2 px-3 max-w-sm text-md font-normal text-primary bg-white rounded-lg opacity-0 tooltip shadow-lg"
  >
    <slot />
  </div>
</template>

<script setup>
import QuestionMarkFilledIn from '@/assets/QuestionMarkFilledIn.svg';
const props = defineProps({
  for: {
    default: '',
    type: String,
  },
});
</script>
