import { defineStore } from 'pinia';
import { apiPost, apiPatch } from '@/utils/api';
import getPath from '@/utils/getPath';
import { toast } from 'vue3-toastify';

export const useChatbotStore = defineStore('chatbot', {
  state: () => ({
    notFound: false,
    responseLoading: false,
    sessionID: '',
    userFeedback: [],
  }),
  getters: {
    //no getters yet
  },
  actions: {
    fetchFeedbackByID(feedbackID) {
      return this.userFeedback.filter(
        message => message.responseID == feedbackID
      );
    },
    async generateAndFormatResponse(rspGroup, user, options = {}) {
      const payload = {
        message: options.prompt || '',
        extraDetails: {
          phase: rspGroup.phase,
          status: rspGroup.status,
          reason: rspGroup.reason,
        },
        userID: user.auxo_user_id,
        sessionID: this.sessionID || crypto.randomUUID(),
        rsn: rspGroup.rsn,
      };

      const endpoint = options.endpoint ? `chat/${options.endpoint}` : 'chat';
      const response = await apiPost(getPath(endpoint), payload);

      this.sessionID = response.sessionID;
      const output = response.response_text ?? null;
      const presignedURL = response.presignedURL ?? '';
      const responseID = response.turn_id;
      const formattedResponse = {
        body: output,
        imageUrl: '',
        linkText: '',
        linkUrl: presignedURL,
        sessionID: this.sessionID,
        responseID: responseID.toString(),
      };

      this.userFeedback.push(formattedResponse);

      return formattedResponse;
    },

    async submitFeedback(
      feedbackType,
      feedbackReason,
      feedbackDescription,
      turn_id
    ) {
      const payload = {
        turn_id,
        feedbackType,
        feedbackDescription,
        feedbackReason,
      };
      try {
        const endpoint = `chat/${this.sessionID}/feedback/${turn_id}`;
        const response = await apiPatch(getPath(endpoint), payload);
        const index = this.userFeedback.findIndex(
          message => message.responseID === turn_id
        );

        if (!response) {
          toast.error('Error submitting feedback');
          return;
        }

        if (index !== -1) {
          this.userFeedback[index].feedbackType = feedbackType;
          this.userFeedback[index].responded = true;
        }
        return response;
      } catch (err) {
        toast.error(err.response?.data?.message ?? err.message);
        console.error(err.response ?? err);
      }
    },
  },
});
