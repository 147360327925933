<template>
  <div class="flex items-center justify-center">
    <label
      :for="id"
      class="flex items-center cursor-pointer"
      :class="{
        '!cursor-default': disabled(),
      }"
    >
      <!-- toggle -->
      <div class="relative">
        <!-- input -->
        <input
          :id="id"
          type="checkbox"
          class="sr-only"
          :checked="checked"
          :disabled="disabled()"
          @change="handleCheckboxClick"
        />
        <!-- line -->
        <div
          class="bg-white w-[96px] h-8 rounded-full border-primary border flex items-center text-primary px-2"
          :class="{
            'justify-end px-3': !checked,
            'justify-start': checked,
            '!border-primary-600 !text-primary-600': disabled(),
          }"
        >
          {{ checked ? 'And' : 'Or' }}
        </div>
        <!-- dot -->
        <div
          class="dot absolute left-0 top-0 bg-primary w-14 h-8 rounded-full transition text-white flex justify-center items-center"
          :class="{
            ' !bg-primary-600': disabled(),
          }"
        >
          {{ !checked ? 'And' : 'Or' }}
        </div>
      </div>
      <!-- label -->
      <!-- <div class="ml-3 text-gray-500 font-bold">Label Toggle</div> -->
    </label>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { CONDITIONALS } from './constants';

const checked = ref(false);

const queryBuilder = useQueryBuilderStore();

const disabled = () => props.rule?.rules?.length < 2 || props.disabled;

const id = `boolean-buttons-${Date.now()}`;

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  indexChain: {
    type: Array,
    default: () => [],
  },
  rule: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

// Resets the conditional to AND if there are less than two rules in a group
watch(props.rule, () => {
  if (props.rule?.rules?.length < 2) {
    checked.value = false;
    queryBuilder.setConditional(
      CONDITIONALS.AND,
      props.level,
      props.indexChain
    );
  }

  checked.value = props.rule.condition == 'OR';
});

const handleCheckboxClick = () => {
  checked.value = !checked.value;

  if (checked.value) {
    queryBuilder.setConditional(CONDITIONALS.OR, props.level, props.indexChain);
  } else {
    queryBuilder.setConditional(
      CONDITIONALS.AND,
      props.level,
      props.indexChain
    );
  }
};
</script>

<style scoped>
table#game_plan_table input:checked ~ .dot {
  transform: translateX(calc(6em - 2.7rem));
}

input:checked ~ .dot {
  transform: translateX(calc(6em - 3.5rem));
}
label {
  user-select: none;
}
</style>
