<template>
  <PageView>
    <div class="flex flex-col w-full text-sm">
      <div class="h-[50px]">
        <p class="text-rev-2xl text-primary font-bold">Users</p>
      </div>
      <div class="users-table overflow-auto drop-shadow-md">
        <UsersTable
          :selected-user="selectedUser"
          @setSelectedUser="setSelectedUser"
        />
      </div>
    </div>
  </PageView>
</template>

<script setup>
import { ref, onUnmounted } from 'vue';
import UsersTable from './UsersTable.vue';
import router from '@/router';
import { useWorklistsStore } from '@/stores/useWorklists';

import PageView from '../../components/PageVue.vue';
import { USER_ROLE_TITLES } from '@/utils/constants';

const worklists_store = useWorklistsStore();
const selectedUser = ref(null);

onUnmounted(() => {
  worklists_store.userWorklists = [];
});

async function setSelectedUser(user) {
  if (selectedUser.value?.auxo_user_id === user?.auxo_user_id) {
    router.push({ path: `/users/${user.auxo_user_id}` });
  } else {
    selectedUser.value = {
      ...user,
      role_title: USER_ROLE_TITLES[user.user_role],
    };
  }
}
</script>

<style scoped></style>
