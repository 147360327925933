<template>
  <Popper hover placement="bottom" :disabled="disablePopper">
    <LoadingSpinner v-if="props.user?.photo_url == null" />
    <div v-else>
      <div
        v-if="props.user?.photo_url === 'none'"
        class="flex object-cover w-10 h-10 rounded-full bg-white text-center text-monospace justify-center items-center text-primary text-[18px] border border-primary"
        :class="props?.style"
      >
        {{
          props.user?.initials ||
          users_store.getUserInitials(props.user?.username)
        }}
      </div>
      <img
        v-else
        ref="dropdownRef"
        class="min-w-[2.5rem] inline-block object-cover w-10 h-10 rounded-full bg-white border border-primary"
        :class="props?.style"
        alt="Profile image"
        :src="props.user?.photo_url"
      />
    </div>
    <template #content>
      <div
        class="bg-white p-1 rounded border-solid border border-primary text-sm text-slate-800 user-full-name"
      >
        <span>
          {{
            `${getUserFullName(props.user?.username)}` +
            `${props.user?.is_active ? '' : ' (inactive)'}`
          }}</span
        >
      </div>
    </template>
  </Popper>
</template>

<script setup>
import Popper from 'vue3-popper';
import { useUsersStore } from '@/stores/useUsers';
import { storeToRefs } from 'pinia';
import LoadingSpinner from './LoadingSpinner';

const users_store = useUsersStore();

const { getUserFullName } = storeToRefs(users_store);

const props = defineProps({
  user: {
    type: Object,
    default: () => {},
  },
  style: {
    default: '',
    type: String,
  },
  disablePopper: {
    default: false,
    type: Boolean,
  },
  override_css: {
    default: false,
    type: Boolean,
  },
});
</script>
