<template>
  <div></div>
</template>

<script setup>
import { watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

import { useMetrics } from '@/stores/useMetrics';
import { useUsersStore } from '@/stores/useUsers';
import { logUserAnalytics } from '@/utils/helpers';

const metrics_store = useMetrics();
const users_store = useUsersStore();

const route = useRoute();

/*
  If there's a work item id, then we're in a work item route (i.e. lead view)
  When we encounter this route, we set the currentTabID to a UUID

  If we're not on a work item, we want to check if we need to send out
  metrics for the time spent on this.

  Life cycle to check for changes:
  - Routing to/from this component
  - On mount
  - On unmounted
  - During beforeunload <- vue has no lifecycle hook for this browser event,
                           need to add eventlistener when the component mounts
*/

const run_analytics = work_item_id => {
  logUserAnalytics(users_store, metrics_store, work_item_id);
};

watch(route, async to => {
  const workItemId = to.params?.id;
  run_analytics(workItemId);
});

onMounted(() => {
  const workItemId = route.params?.id;
  window.addEventListener('beforeunload', () => {
    run_analytics(null);
  });
  if (workItemId) {
    run_analytics(workItemId);
  }
});

onUnmounted(() => {
  run_analytics(null);
});
</script>

<style lang="css" scoped></style>
