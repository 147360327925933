import { defineStore } from 'pinia';
import { groupBy } from 'lodash';
import { toast } from 'vue3-toastify';
import {
  sort_options_alphabetically,
  destructure_options,
} from '../utils/helpers';
import getPath from '@/utils/getPath';
import { apiGet } from '@/utils/api';

export const useLookupStore = defineStore('lookups', {
  state: () => ({
    payerPlansLoading: false,
    payerPlans: [],
  }),
  getters: {
    getPayerById: state => {
      return payerId => {
        return state.payerPlans.find(el => el.payer_code == payerId).payer_name;
      };
    },
    getPayerIdByName: state => {
      return payer_name => {
        return state.payerPlans.find(el => el.payer_name == payer_name)
          .payer_id;
      };
    },
    getPayerPlanOptions: state => {
      const options = Object.entries(
        groupBy(state.payerPlans, 'payer_code')
      ).flatMap(entry => {
        const [key, value] = entry;

        const parent = {
          level: 0,
          label: state.getPayerById(key),
          value: {
            payer_code: key,
          },
          parent: null,
          isPageIndicator: false,
        };

        const children = value.map(el => ({
          label: el.plan_name,
          child_label: `${parent.label} > ${el.plan_name}`,
          value: {
            plan_id: el.plan_id,
            payer_code: el.payer_code,
          },
          level: 1,
          parent,
          children: [],
          rest: {
            ...el,
          },
          isPageIndicator: false,
        }));

        return [{ ...parent, children }, ...children];
      });

      const organized_options = sort_options_alphabetically(options);
      const result = destructure_options(organized_options);

      return result;
    },
  },
  actions: {
    async getPayerPlans() {
      this.payerPlansLoading = true;
      this.payerPlans = [];

      try {
        const responseData = await apiGet(getPath('lookups/payerPlan'));

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          this.payerPlans = responseData.data;
          return responseData;
        }
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? err);
        toast.error(errorBody?.message ?? err.status);
        return null;
      } finally {
        this.payerPlansLoading = false;
      }
    },
  },
});
