<template>
  <div class="flex justify-center">
    <div class="flex justify-center max-w-[175px] overflow-hidden pl-1.5">
      <template
        v-for="(user, templateIndex) in users?.slice(0, 3)"
        :key="templateIndex"
      >
        <div class="-ml-1.5">
          <UserPicture
            :user="users_store.getUserByUsername(user.username)"
            :style="'bg-white'"
          />
        </div>
      </template>
      <div
        @mouseover="showExtraUsers = true"
        @mouseleave="showExtraUsers = false"
      >
        <div id="extra_users">
          <Popper
            :hover="showExtraUsers"
            :show="showExtraUsers && users?.length > 3"
          >
            <div
              v-show="users?.length > 3"
              class="rounded-full -ml-1.5 p-1 px-2 bg-white text-primary text-[18px] w-10 h-10 text-center text-monospace flex justify-center items-center border border-primary"
            >
              +{{ users?.length - 3 }}
            </div>
            <template #content>
              <div
                ref="userList"
                class="bg-white p-1 rounded border-solid border border-primary text-sm text-slate-800 max-h-52 overflow-auto whitespace-nowrap"
                @mouseleave="scrollToTop"
              >
                <template
                  v-for="user in getGroupOfUsersNames(users.slice(3))"
                  :key="user.name"
                >
                  <div>
                    {{ user.name + `${user.is_active ? '' : ' (inactive)'}` }}
                  </div>
                </template>
              </div>
            </template>
          </Popper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserPicture from '@/components/UserPicture.vue';
import { useUsersStore } from '@/stores/useUsers';
import { ref } from 'vue';
import Popper from 'vue3-popper';
import { storeToRefs } from 'pinia';

const users_store = useUsersStore();
const { getGroupOfUsersNames } = storeToRefs(users_store);
const showExtraUsers = ref(false);
const userList = ref(null);

const scrollToTop = () => {
  userList.value.scrollTop = 0;
};

defineProps({
  users: {
    type: Array,
    default: () => [],
  },
});
</script>
