<template>
  <table class="text-left table-fixed drop-shadow-none">
    <thead class="">
      <tr>
        <!-- <th class="w-7 place-content-center">
          <input class="cursor-pointer mt-1.5" type="checkbox" />
        </th> -->
        <template
          v-for="(header, templateIndex) in HEADERS"
          :key="templateIndex"
        >
          <TableHeader
            :id="header.key"
            :title="header.value"
            :show-sort="header.sort"
            :width="header.width"
            :type="header.type"
            :source="header.source"
            :title-styling="'text-rev-xl text-gray-700'"
            @sort="sortAssignments"
          />
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-show="assignments_store.isRecentLoading">
        <td :colspan="HEADERS.length">
          <div class="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </td>
      </tr>
      <tr
        v-show="
          !assignments_store.isRecentLoading &&
          assignments_store.recentAssignments.length === 0
        "
      >
        <td :colspan="HEADERS.length">
          <div class="flex items-center justify-center h-40">
            <p>No Recent Activity</p>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody
      v-show="
        !assignments_store.isRecentLoading &&
        assignments_store.recentAssignments.length > 0
      "
      class="text-rev-sm font-normal text-gray-700"
    >
      <template
        v-for="(item, index) in assignments_store.recentAssignments"
        :key="index"
      >
        <tr
          :data-index="index"
          class="z-10 cursor-pointer text-gray-700"
          :class="{
            'bg-gray-100': index % 2 === 1,
            'bg-white': index % 2 === 0,
            'outline outline-1 outline-primary-300 bg-primary-100':
              props.selectedRow === item.work_item_id,
          }"
          @click="
            props.selectedRow === item.work_item_id
              ? emit('showDetails', item.work_item_id)
              : emit('setSelectedRow', item.work_item_id)
          "
        >
          <!-- <td class="w-7 place-content-center">
            <input class="cursor-pointer mt-1" type="checkbox" />
          </td> -->
          <td
            v-for="(header, tableDataIndex) in HEADERS"
            v-show="header.key !== 'assignees'"
            :key="tableDataIndex"
            class="ml-1 !w-[200px]"
          >
            <TableCellView :item="item" :header="header" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <!-- <VuePagination
    v-if="!assignments_store.isRecentLoading"
    :page="assignments_store.recentPage"
    local_store="recent_lead_pagination"
    type="Recent Items"
    :records="assignments_store.recentRecords"
    :limit="Number(assignments_store.recentLimit)"
    @paginate="paginate"
    @updateLimit="updateLimit"
  /> -->
</template>

<script setup>
import TableHeader from '@/components/table/TableHeader.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useAssignmentsStore } from '@/stores/useAssignments';
// import VuePagination from '@/views/VuePagination.vue';
import TableCellView from '../common/TableCellView.vue';
import { useRoute } from 'vue-router';

const assignments_store = useAssignmentsStore();

const emit = defineEmits(['showDetails', 'setSelectedRow']);

const route = useRoute();

// async function paginate(page) {
//   await assignments_store.fetchRecentUserAssignments(
//     props.auxo_user_id,
//     page,
//     assignments_store.recentDirection,
//     assignments_store.recentField
//   );
// }

// async function updateLimit(limit) {
//   assignments_store.recentLimit = limit.value;
//   await assignments_store.fetchRecentUserAssignments(
//     props.auxo_user_id,
//     1,
//     assignments_store.recentDirection,
//     assignments_store.recentField
//   );
// }

const props = defineProps({
  'selected-row': {
    type: Number,
    default: null,
  },
  auxo_user_id: {
    type: String,
    default: null,
  },
});

const HEADERS = [
  { key: 'claim_number', value: 'Claim Number', sort: true },
  { key: 'patient_name', value: 'Patient Name', sort: true },
  { key: 'current_plan_name', value: 'Plan', sort: true },
  { key: 'phase', value: 'Phase', sort: true },
  { key: 'status', value: 'Status', sort: true },
  { key: 'reason', value: 'Reason', sort: true },
  {
    key: 'mod_timest',
    value: 'Completion Date',
    sort: true,
    type: 'date',
    source: 'lead',
    format: 'Pp',
    truncateTimezone: false,
  },
  {
    key: 'activity_subtype',
    value: 'Completion Action',
    sort: true,
  },
  { key: 'is_priority', value: '', sort: false, width: '7em' },
];

async function sortAssignments(direction, field) {
  const user_id =
    route.name == 'lead' ? props.auxo_user_id : route.params.auxo_user_id;
  await assignments_store.fetchRecentUserAssignments(
    user_id,
    1,
    direction,
    field
  );
  assignments_store.recentDirection = direction;
  assignments_store.recentField = field;
  assignments_store.recentPage = 1;
}
</script>
